import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './BannerPrincipal.scss'
import ic_search from '../../../assets/icons/mm_ic_search.svg'
import { useNavigate } from 'react-router-dom'
import { Constants } from '../../../Constants'

const imageArray = [
  require('../../../assets/images/home-mobile/1.jpg'),
  require('../../../assets/images/home-mobile/2.jpg'),
  require('../../../assets/images/home-mobile/3.jpg'),
  require('../../../assets/images/home-mobile/4.jpg'),
  require('../../../assets/images/home-mobile/6.jpg'),
  require('../../../assets/images/home-mobile/7.jpg'),
  require('../../../assets/images/home-mobile/8.jpg'),
]

export default function BannerPrincipal() {
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('0')
  const [type, setType] = useState('1')
  const navigate = useNavigate()
  const [categories] = useState([
    { id: '0', name: 'Todos' },
    { id: '1', name: 'Apartamentos' },
    { id: '2', name: 'Campos' },
    { id: '3', name: 'Casas' },
    { id: '4', name: 'Terrenos' },
    { id: '5', name: 'Otros' },
    { id: '6', name: 'Locales Comerciales' },
    { id: '7', name: 'Oficinas' },
    { id: '8', name: 'Chacras' },
    { id: '9', name: 'Galpónes' },
  ])
  const [imgMobile, setImgMobile] = useState(obtenerImagenAleatoria())

  const handleChange = (event: any) => {
    const { name, value } = event.target
    if (name === 'category') {
      setCategory(value)
    } else if (name === 'type') {
      setType(value)
    } else {
      setSearch(value)
    }
  }

  const searchAction = async () => {
    if (category !== '0') {
      const categorySelected: any = categories.filter((item: any) => item.id === category)[0]
      sessionStorage.setItem(
        'category',
        JSON.stringify([
          {
            name: categorySelected.name,
            type: Constants.FILTER_CATEGORY,
            filter: categorySelected.id,
          },
        ])
      )
    }
    sessionStorage.setItem('search', search)
    sessionStorage.setItem('type', type)
    sessionStorage.setItem('comeFromSearch', '{ comeFromSearch: true }')
    navigate('/propiedades')
  }

  function obtenerImagenAleatoria() {
    const indiceAleatorio = Math.floor(Math.random() * imageArray.length)
    return imageArray[indiceAleatorio]
  }

  return (
    <div id="banner-principal">
      <video
        muted
        id="scrollVideo"
        autoPlay
        loop
        playsInline={true}
        style={{ width: '100%', height: '100%' }}
        className="banner-video banner-video-desktop"
        src="https://mariomendez.uy/api/public/MGI_2023.mp4"
      />
      <div className="blur-black" />
      <div className="banner-video banner-video-mobile">
        <img
          src={imgMobile}
          style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
          alt=""
        />
      </div>
      <div className="search-container">
        <div>
          <h3
            style={{
              width: '100%',
              textAlign: 'center',
              color: 'white',
              fontSize: '2rem',
              marginBottom: '0.75rem',
              letterSpacing: '1px',
            }}
          >
            Encontrá la propiedad que buscas
          </h3>
          <div className="search-container-box">
            <div className="filter-box">
              <select
                name="type"
                value={type}
                onChange={handleChange}
                style={{ border: 'none', padding: '0rem 0.75rem', marginRight: '0.5rem' }}
              >
                <option value="1">Venta</option>
                <option value="2">Alquiler</option>
              </select>
              <select
                name="category"
                value={category}
                onChange={handleChange}
                style={{ border: 'none', padding: '0rem 0.75rem', marginRight: '0.5rem' }}
              >
                <option value="0">Todos</option>
                <option value="1">Apartamentos</option>
                <option value="2">Campos</option>
                <option value="3">Casas</option>
                <option value="4">Terrenos</option>
                <option value="5">Otros</option>
                <option value="6">Locales Comerciales</option>
                <option value="7">Oficinas</option>
                <option value="8">Chacras</option>
                <option value="9">Galpónes</option>
              </select>
              <input
                value={search}
                onChange={handleChange}
                name="search"
                style={{
                  border: 'none',
                  padding: '0rem 1rem',
                  width: '350px',
                  marginRight: '0.5rem',
                }}
                placeholder="Ubicación, palabras claves o referencia"
              />
              <button
                style={{
                  background: '#013399',
                  color: 'white',
                  border: 'none',
                  padding: '0.5rem 1rem',
                }}
                onClick={searchAction}
              >
                Buscar <img src={ic_search} alt="" width="25" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
