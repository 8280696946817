import axios from 'axios'
import { Constants } from '../Constants'

const instance = axios.create({ baseURL: Constants.BASE_URL })

export const fetchAboutUs = async () => {
  try {
    return await instance.get(`/aboutUs`)
  } catch (error) {
    throw error
  }
}

export const fetchAgent = async () => {
  try {
    return await instance.get(`/agent`)
  } catch (error) {
    throw error
  }
}

export const fetchAccount = async () => {
  try {
    return await instance.get(`/account`)
  } catch (error) {
    throw error
  }
}

export const fetchHighList = async (page: number, typeSelected: string | null) => {
  try {
    return await instance.get(`/properties/highlight?page=${page}&typeSelected=${typeSelected}`)
  } catch (error) {
    throw error
  }
}

export const postSub = async (data: any) => {
  try {
    return await instance.post(`/subs`, data)
  } catch (error) {
    throw error
  }
}

export const postContact = async (data: any) => {
  try {
    return await instance.post(`/contact`, data)
  } catch (error) {
    throw error
  }
}
