let timer: any = null

export const SmoothScrolling = {
  timer: null,

  stop: function () {
    clearTimeout(timer)
  },

  scrollTo: function (id: string, callback?: CallableFunction) {
    var settings = {
      duration: 100,
      easing: {
        outQuint: function (x: number, t: number, b: number, c: number, d: number) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b
        },
      },
    }
    var percentage
    var startTime: any
    var node = document.getElementById(id)
    var nodeTop = node?.offsetTop || 0
    var nodeHeight = node?.offsetHeight || 0
    var body = document.body
    var html = document.documentElement
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    var windowHeight = window.innerHeight
    var offset = window.pageYOffset
    var delta = nodeTop - offset
    var bottomScrollableY = height - windowHeight
    var targetY =
      bottomScrollableY < delta
        ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
        : delta

    startTime = Date.now()
    percentage = 0

    if (timer) {
      clearInterval(timer)
    }

    function step() {
      var yScroll
      var elapsed = Date.now() - startTime

      if (elapsed > settings.duration) {
        clearTimeout(timer)
      }

      percentage = elapsed / settings.duration

      if (percentage > 1) {
        clearTimeout(timer)

        if (callback) {
          callback()
        }
      } else {
        yScroll = settings.easing.outQuint(0, elapsed, offset - 20, targetY, settings.duration)
        window.scrollTo(0, yScroll)
        timer = setTimeout(step, 10)
      }
    }

    timer = setTimeout(step, 10)
  },
}
