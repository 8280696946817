import React, { useState } from 'react'
import Loading from '../loading/Loading'
import './Contact.scss'
import { postContact } from '../../services/SharedServices'
import { toast, ToastContainer } from 'react-toastify'
import Offices from './Offices'

const ContactForm = () => {
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    operation: 'Quiero comprar',
  })
  const [loading, setLoading] = useState(false)

  const handleFieldChange = (field: string, value: string) => {
    setContact({
      ...contact,
      [field]: value,
    })
  }

  const sendContact = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      await postContact({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        message: contact.message,
        operation: contact.operation,
      })
      setContact({
        name: '',
        email: '',
        phone: '',
        message: '',
        operation: 'Quiero comprar',
      })
      setLoading(false)
      toast(`Consulta enviada con éxito`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    } catch (error: any) {
      setLoading(false)
      toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <ToastContainer />
      <Offices />
      <div className="container" style={{ marginBottom: '4rem' }}>
        <div
          className="row"
          style={{ marginBottom: '2rem', marginTop: '4rem', padding: '0rem 2rem' }}
        >
          <div className="col-12 text-center">
            <h3>Queremos saber que necesita, póngase en contacto con nosotros!</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="contact-form">
              <form onSubmit={(e) => sendContact(e)}>
                <div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        required={true}
                        placeholder="* Nombre Completo"
                        value={contact.name}
                        onChange={(e) => handleFieldChange('name', e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={contact.email}
                        onChange={(e) => handleFieldChange('email', e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        required={true}
                        placeholder="* Teléfono"
                        value={contact.phone}
                        onChange={(e) => handleFieldChange('phone', e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={contact.operation}
                        onChange={(e) => handleFieldChange('operation', e.target.value)}
                      >
                        <option value="Quiero alquilar">Quiero alquilar</option>
                        <option value="Quiero comprar">Quiero comprar</option>
                        <option value="Quiero vender">Quiero vender</option>
                        <option value="Quiero tasar">Quiero tasar</option>
                        <option value="Quiero hacer una consulta">Quiero hacer una consulta</option>
                      </select>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        cols={30}
                        rows={8}
                        required={true}
                        className="form-control"
                        placeholder="* Mensaje"
                        value={contact.message}
                        onChange={(e) => handleFieldChange('message', e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 text-center">
                    <button type="submit" className="">
                      Enviar
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactForm
