import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/Footer'
import BannerAgent from '../../components/home/bannerAgent/BannerAgent'
import Navigation from '../../components/navigation/Navigation'
import './styles.scss'
import axios from 'axios'
import { Constants } from '../../Constants'
import { Col, Row } from 'antd'
import { postContact } from '../../services/SharedServices'
import { toast, ToastContainer } from 'react-toastify'
import Loading from '../../components/loading/Loading'

const instance = axios.create({ baseURL: Constants.BASE_URL })

const fetchBrokers = async () => {
  try {
    return await instance.get(`/brokers?type=comercial`)
  } catch (error) {
    throw error
  }
}

export default function Agent() {
  const [bannerDesktop, setBannerDesktop] = useState('')
  const [bannerMobile, setBannerMobile] = useState('')
  const [head, setHead] = useState('')
  const [footer, setFooter] = useState('')
  const [content, setContent] = useState([])
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [operation, setOperation] = useState('Quiero vender')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      let response = await fetchBrokers()
      const { banner_desktop, banner_mobile, footer, head, content } = response.data.data
      setBannerDesktop(banner_desktop)
      setBannerMobile(banner_mobile)
      setHead(head)
      setFooter(footer)
      const contentArr = JSON.parse(content)
      setContent(contentArr.filter((item: any) => item.image && item.description !== '<p><br></p>'))
    } catch (error) {}
  }

  const sendContact = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      await postContact({
        name: name,
        email: email,
        phone: phone,
        message: message,
        operation: operation,
      })
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
      setOperation('Quiero vender')
      setLoading(false)
      toast(`Consulta enviada con éxito`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    } catch (error: any) {
      setLoading(false)
      toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    }
  }

  return (
    <div>
      {loading && <Loading />}
      <ToastContainer />
      <Navigation />
      <div id="Agent">
        <div style={{ marginBottom: '2rem' }}>
          <div style={{ marginBottom: '4rem' }}>
            <div className="col-12 text-center d-none d-md-block">
              <img
                src={`${Constants.BASE_URL}${bannerDesktop}`}
                alt=""
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <div className="col-12 d-block d-md-none" style={{ padding: '0rem' }}>
              <img
                src={`${Constants.BASE_URL}${bannerMobile}`}
                alt=""
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </div>
          <div className="container">
            <div style={{ marginBottom: '4rem' }}>
              <div className="col-12 text-left">
                <div dangerouslySetInnerHTML={{ __html: head }} />
              </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
              <div className="row" id="item">
                {content.map((item: any) => (
                  <div className="col-md-4 col-12">
                    {item.image ? (
                      <img
                        data-dz-thumbnail=""
                        alt=""
                        src={`${Constants.BASE_URL}${item.image}`}
                        style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}
                      />
                    ) : null}
                    {item.description && item.description !== '<p><br></p>' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className="container">
              <div className="row" style={{ marginBottom: '4rem', marginTop: '4rem' }}>
                <div className="col-12 text-center">
                  <div dangerouslySetInnerHTML={{ __html: footer }} />
                </div>
                <div className="col-12 text-center">
                  <div
                    style={{
                      width: '100%',
                      margin: 'auto',
                      borderRadius: '5px',
                      marginTop: '1rem',
                    }}
                  >
                    <form onSubmit={(e) => sendContact(e)}>
                      <div className="inputs-contact">
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          placeholder="* Nombre"
                        />
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                          placeholder="* Whatsapp"
                        />
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        <select
                          value={operation}
                          onChange={(e) => setOperation(e.target.value)}
                          required
                        >
                          <option value="Quiero alquilar">Quiero alquilar</option>
                          <option value="Quiero comprar">Quiero comprar</option>
                          <option value="Quiero vender">Quiero vender</option>
                          <option value="Quiero tasar">Quiero tasar</option>
                          <option value="Quiero hacer una consulta">
                            Quiero hacer una consulta
                          </option>
                        </select>
                      </div>
                      <textarea
                        name="message"
                        cols={30}
                        rows={4}
                        required={true}
                        placeholder="* Mensaje"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <button
                        style={{
                          width: '100%',
                          background: '#013399',
                          border: 'none',
                          padding: '0.5rem',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          borderRadius: '5px',
                          color: 'white',
                        }}
                        type="submit"
                      >
                        ¡Enviar solicitud!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
