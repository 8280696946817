import React, { useEffect, useState } from 'react'
import PropertyItem from '../../properties/item'
import './MarkedProperties.scss'
import { fetchHighList } from '../../../services/SharedServices'
import Loading from '../../loading/Loading'
import Pagination from '../../pagination/Pagination'
import useNearScreen from '../../../hooks/useNearScreen'

export default function MarkedProperties({ title }: any) {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(10)
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(false)
  const [typeSelected, setTypeSelected] = useState('sale')
  const [first, setFirst] = useState(true)
  const [salesQty, setSalesQty] = useState(0)
  const [rentQty, setRentQty] = useState(0)
  const { isNearScreen, elementRef } = useNearScreen({ distance: '-120px', once: false })
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    getHighList()
  }, [page, typeSelected, isMobile])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.innerWidth])

  useEffect(() => {
    if (isNearScreen) {
      setPage(() => page + 1)
    }
  }, [isNearScreen])

  const getHighList = async () => {
    setLoading(true)
    try {
      if (isMobile) {
        let response = await fetchHighList(page, null)
        const { pages, properties: properts, rent, sales } = response.data
        const new_properties = properties.concat(properts)
        setPages(pages)
        setProperties(new_properties)
        setRentQty(rent)
        setSalesQty(sales)
        setLoading(false)
      } else {
        let response = await fetchHighList(page, typeSelected)
        const { pages, properties, rent, sales } = response.data
        setPages(pages)
        setProperties(properties)
        setRentQty(rent)
        setSalesQty(sales)
        setLoading(false)
        if (!first) {
          const doc = document.getElementById('properties-list')
          if (doc) {
            doc.scrollIntoView()
          }
        } else {
          setFirst(false)
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div id="market-properties" className="container" style={{ marginTop: '6rem' }}>
      {loading && <Loading />}
      <div className="row" id="properties-list">
        <div className="col-12 text-center">
          <h3>{title}</h3>
        </div>
      </div>
      {salesQty > 0 && rentQty > 0 && !isMobile ? (
        <div className="row" style={{ marginTop: '1.5rem' }}>
          <div className="col-12 text-center selection-type">
            <button
              className={`${typeSelected === 'sale' && 'active'}`}
              style={{ marginRight: '2rem' }}
              onClick={() => setTypeSelected('sale')}
            >
              VENTA
            </button>
            <button
              onClick={() => setTypeSelected('rent')}
              className={`${typeSelected === 'rent' && 'active'}`}
            >
              ALQUILER
            </button>
          </div>
        </div>
      ) : null}
      <div className="row" style={{ marginTop: '2rem' }}>
        {properties.map((item: any) => (
          <PropertyItem key={item.id} item={item} />
        ))}
      </div>
      {!isMobile ? (
        <div className="row" style={{ marginTop: '1rem' }}>
          <div className="col-12 text-center">
            <Pagination
              pages={pages}
              page={page}
              onPageProp={(page: number) => setPage(page)}
              onPrevProp={() => setPage(page - 1)}
              onNextProp={() => setPage(page + 1)}
            />
          </div>
        </div>
      ) : null}
      {isMobile && properties.length > 0 ? <div id="observer" ref={elementRef}></div> : ''}
    </div>
  )
}
