import logo from '../../../assets/images/LOGO MGI azul.png'
import ic_contact from '../../../assets/icons/mm_contact.png'
import ic_news from '../../../assets/icons/mm_news.png'
import ic_services from '../../../assets/icons/mm_services.png'
import ic_facebook from '../../../assets/icons/mm_ic_facebook.svg'
import ic_youtube from '../../../assets/icons/mm_ic_youtube.svg'
import ic_wpp from '../../../assets/icons/mm_ic_wpp.svg'
import ic_twitter from '../../../assets/icons/mm_ic_twitter.svg'
import ic_linkedin from '../../../assets/icons/mm_ic_linkedin.svg'
import ic_instagram from '../../../assets/icons/mm_ic_instagram.svg'
import './BannerAgent.scss'
import { useEffect, useState } from 'react'
import { fetchAccount, fetchAgent } from '../../../services/SharedServices'
import { Constants } from '../../../Constants'
import { Link } from 'react-router-dom'

export default function BannerAgent() {
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [icons, setIcons] = useState<any[]>([])
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [telegram, setTelegram] = useState('')
  const [twitter, setTwitter] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [youtube, setYoutube] = useState('')

  useEffect(() => {
    fetch()
    getAccount()
  }, [])

  const fetch = async () => {
    try {
      let response = await fetchAgent()
      const {
        description,
        image,
        enlace1,
        enlace2,
        enlace3,
        icon1,
        icon2,
        icon3,
        title1,
        title2,
        title3,
      } = response.data.data.config
      setDescription(description)
      setImage(image)
      let icons = []
      if (icon1 && enlace1) {
        icons.push({
          link: enlace1,
          icon: icon1,
          title: title1,
        })
      }
      if (icon2 && enlace2) {
        icons.push({
          link: enlace2,
          icon: icon2,
          title: title2,
        })
      }
      if (icon3 && enlace3) {
        icons.push({
          link: enlace3,
          icon: icon3,
          title: title3,
        })
      }
      setIcons(icons)
    } catch (error) {}
  }

  const getAccount = async () => {
    try {
      let response = await fetchAccount()
      const { facebook, instagram, linkedin, telegram, twitter, whatsapp, youtube } =
        response.data.data.config
      setFacebook(facebook)
      setInstagram(instagram)
      setLinkedin(linkedin)
      setTelegram(telegram)
      setTwitter(twitter)
      setWhatsapp(whatsapp)
      setYoutube(youtube)
    } catch (error) {}
  }

  return (
    <div id="banner-agent" className="container" style={{ marginTop: '4rem' }}>
      <div className="row">
        <div className="col-lg-6 col-12 text-center agent">
          <div>
            <img src={`${Constants.BASE_URL}/${image}`} alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 text-center welcome" style={{ display: 'grid' }}>
          <img src={logo} alt="" style={{ width: '75%', margin: 'auto' }} />
          <div
            className="text-left"
            style={{ marginTop: 'auto', marginBottom: '1rem' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {icons.length ? (
            <div
              className="features"
              style={{
                display: 'flex',
                marginTop: 'auto',
                width: '100%',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              {icons.map((item: any) => (
                <Link className="item" to={item.link}>
                  <img
                    src={`${Constants.BASE_URL}/${item.icon}`}
                    alt=""
                    style={{
                      width: 'auto',
                      cursor: 'pointer',
                      height: '80px',
                    }}
                  />
                  <span>{item.title}</span>
                </Link>
              ))}
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              marginTop: '2rem',
              width: '100%',
              justifyContent: 'end',
            }}
          >
            <span>Síguenos:</span>
            {facebook !== '' ? (
              <a href={facebook}>
                <img
                  src={ic_facebook}
                  alt=""
                  style={{ width: 'auto', cursor: 'pointer', height: '24px' }}
                />
              </a>
            ) : null}
            {instagram !== '' ? (
              <a href={instagram}>
                <img
                  src={ic_instagram}
                  alt=""
                  style={{
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    marginRight: '0.5rem',
                  }}
                />
              </a>
            ) : null}
            {linkedin !== '' ? (
              <a href={linkedin}>
                <img
                  src={ic_linkedin}
                  alt=""
                  style={{
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    marginRight: '0.5rem',
                  }}
                />
              </a>
            ) : null}
            {twitter !== '' ? (
              <a href={twitter}>
                <img
                  src={ic_twitter}
                  alt=""
                  style={{
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    marginRight: '0.5rem',
                  }}
                />
              </a>
            ) : null}
            {whatsapp !== '' ? (
              <a href={whatsapp}>
                <img
                  src={ic_wpp}
                  alt=""
                  style={{
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    marginRight: '0.5rem',
                  }}
                />
              </a>
            ) : null}
            {youtube !== '' ? (
              <a href={youtube}>
                <img
                  src={ic_youtube}
                  alt=""
                  style={{
                    width: 'auto',
                    cursor: 'pointer',
                    height: '24px',
                    marginRight: '0.5rem',
                  }}
                />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
