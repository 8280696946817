import React from 'react'
import './Pagination.scss'
import arrowLeft from '../../assets/icons/arrow-back-24-px.svg'
import arrowRight from '../../assets/icons/arrow-right.svg'
import arrowLeftBlue from '../../assets/icons/arrow-back-24-px blue.svg'
import arrowRightBlue from '../../assets/icons/arrow-right blue.svg'

export default function Pagination({
  page,
  pages,
  marginTop,
  onPrevProp,
  onNextProp,
  onPageProp,
}: any) {
  const onPrev = () => {
    onPrevProp()
  }

  const onNext = () => {
    onNextProp()
  }

  const onPage = (page: number) => {
    onPageProp(page)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="pagination">
            <img
              className={`arrow ${page === 1 && 'arrow-disabled'}`}
              src={page === 1 ? arrowLeft : arrowLeftBlue}
              onClick={onPrev}
              style={marginTop ? { marginTop: marginTop } : {}}
              alt=""
            />
            {page - 2 > 0 && page === pages ? (
              <div className="item" onClick={() => onPage(page - 2)}>
                <span>{page - 2}</span>
              </div>
            ) : null}
            {page - 1 !== 0 ? (
              <div className="item" onClick={() => onPage(page - 1)}>
                <span>{page - 1}</span>
              </div>
            ) : null}
            <div className="item active">
              <span>{page}</span>
            </div>
            {page + 1 <= pages ? (
              <div className="item" onClick={() => onPage(page + 1)}>
                <span>{page + 1}</span>
              </div>
            ) : null}
            {page - 1 === 0 && page + 2 <= pages ? (
              <div className="item" onClick={() => onPage(page + 2)}>
                <span>{page + 2}</span>
              </div>
            ) : null}
            <img
              className={`arrow ${page === pages && 'arrow-disabled'}`}
              src={page === pages ? arrowRight : arrowRightBlue}
              onClick={onNext}
              style={marginTop ? { marginTop: marginTop } : {}}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
