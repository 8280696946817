/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/LOGO MGI.png'
import { Navbar, Nav, Container } from 'react-bootstrap'
import './Navigation.scss'
import { Link } from 'react-router-dom'
import ic_wpp from '../../assets/icons/mm_ic_wpp_white.svg'
import ic_calendar from '../../assets/icons/calendar.svg'
import ic_phone from '../../assets/icons/mm_ic_phone_white.svg'
import { Constants } from '../../Constants'
import axios from 'axios'

const instance = axios.create({ baseURL: Constants.BASE_URL })

type nav = {
  id?: number
  nombre: string
  url: string
}

const fetchNavbar = async () => {
  try {
    return await instance.get(`/navbar`)
  } catch (error) {
    throw error
  }
}

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false)
  const [navbar, setNavbar] = useState<nav[]>([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      let response = await fetchNavbar()
      setNavbar(response.data.data)
    } catch (error) {}
  }

  const handleButton = () => {
    let navbar = document.getElementById('navbar')
    if (!isOpen) {
      document.body.style.overflow = 'hidden'
      if (navbar) {
        navbar.classList.remove('top-0')
      }
      setIsOpen(true)
    } else {
      document.body.style.overflow = 'unset'
      if (navbar && navbar.classList.contains('container')) {
        navbar.classList.add('top-0')
      }
      setIsOpen(false)
    }
  }

  const letsGo = () => {
    document.body.style.overflow = 'unset'
  }

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const pathname = window.location.pathname

  return (
    <React.Fragment>
      <Navbar
        id="navbar"
        collapseOnSelect
        expand="lg"
        className={`navbar ${scrollPosition < 2 && pathname === '/' ? 'top-0 container' : ''}`}
      >
        <Container id="scroll-top">
          <Navbar.Brand className="navbar-brand desktop">
            <Link to="/">
              <img src={logo} className="d-inline-block align-top logo" width="250" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Brand className="navbar-brand mobile">
            <Link to="/">
              <img src={logo} className="d-inline-block align-top logo" width="250" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className="button-menu"
            aria-controls="responsive-navbar-nav"
            onClick={handleButton}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav-options">
              <Nav className="desktop">
                {navbar?.map((nav) => {
                  return (
                    <>
                      <Nav.Link>
                        <Link
                          className={`item ${nav.url === window.location.pathname ? 'active' : ''}`}
                          to={nav.url}
                        >
                          {nav.nombre}
                        </Link>
                      </Nav.Link>
                    </>
                  )
                })}
              </Nav>
              <div className="mobile-navbar">
                <Nav className="flex-column">
                  {navbar?.map((nav) => {
                    return (
                      <>
                        <Nav.Link>
                          <Link
                            className={`item ${
                              nav.url === window.location.pathname ? 'active' : ''
                            }`}
                            to={nav.url}
                            onClick={letsGo}
                          >
                            {nav.nombre}
                          </Link>
                        </Nav.Link>
                      </>
                    )
                  })}
                </Nav>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="options-div">
        <div className="options-div-item">
          <a
            href="https://api.whatsapp.com/send/?phone=59891999222"
            style={{ display: 'flex' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={ic_wpp} alt="" />
            Whatsapp
          </a>
        </div>
        <div className="options-div-item">
          <a
            href="https://mariomendez.uy/agenda"
            style={{ display: 'flex' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={ic_calendar} alt="" />
            Agendar
          </a>
        </div>
        <div className="options-div-item">
          <a href={`tel:59891999222`} style={{ display: 'flex' }} target="_blank" rel="noreferrer">
            <img src={ic_phone} alt="" />
            Teléfono
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}
