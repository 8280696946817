import React, { Component } from 'react'
import './VerticalCard.scss'
import PropertyItem from '../item'

export default function VerticalCard({
  properties,
  pagination,
  isForBooking,
  bookingCallback,
}: any) {
  return (
    <div className="row card-vertical">
      {properties.map((item: any) => (
        <PropertyItem
          key={item.id}
          item={item}
          col={`${!isForBooking ? 'col-md-4' : 'col-md-3'}`}
          isForBooking={isForBooking}
          bookingCallback={bookingCallback}
        />
      ))}
      {pagination}
    </div>
  )
}
