import Footer from '../../components/footer/Footer'
import Navigation from '../../components/navigation/Navigation'
import WhyChooseUs from '../../components/home/whyChooseUs/WhyChooseUs'
import './styles.scss'
import BannerAgent from '../../components/home/bannerAgent/BannerAgent'
import BannerPrincipal from '../../components/home/bannerPrincipal/BannerPrincipal'
import MarkedProperties from '../../components/home/markedProperties/MarkedProperties'
import { Carousel } from 'antd'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import agent from '../../assets/images/agent.jpeg'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ic_home from '../../assets/icons/mm_ic_home_blue.svg'
import ic_comercial from '../../assets/icons/mm_ic_comercial.svg'
import { fetchBanners, fetchHome } from '../../services/HomeServices'
import Loading from '../../components/loading/Loading'
import { Constants } from '../../Constants'
import { postContact } from '../../services/SharedServices'
import { toast, ToastContainer } from 'react-toastify'
import Testimonies from '../../components/home/testimonies/Testimonies'

export default function Home() {
  const [showModal, setShowModal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [title1, setTitle1] = useState('')
  const [title2, setTitle2] = useState('')
  const [title3, setTitle3] = useState('')
  const [description1, setDescription1] = useState('')
  const [description2, setDescription2] = useState('')
  const [description3, setDescription3] = useState('')
  const [icon1, setIcon1] = useState('')
  const [icon2, setIcon2] = useState('')
  const [icon3, setIcon3] = useState('')
  const [footerImage, setFooterImage] = useState('')
  const [banners, setBanners] = useState([])

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [operation, setOperation] = useState('Quiero comprar')
  const [message, setMessage] = useState('')

  useEffect(() => {
    fetch()
    getBanners()
    const dateShowModal = localStorage.getItem('dateShowModal')
    if (
      dateShowModal &&
      moment(dateShowModal).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
    ) {
      setShowModal(false)
    } else {
      localStorage.setItem('dateShowModal', moment().format('YYYY-MM-DD'))
      setShowModal(true)
    }
  }, [])

  const handleClose = () => {
    setShowModal(false)
  }

  const fetch = async () => {
    setLoading(true)
    try {
      let response = await fetchHome()
      const {
        title1,
        title2,
        title3,
        description1,
        description2,
        description3,
        logo1,
        logo2,
        logo3,
        footerImage,
      } = response.data.data.config
      setTitle1(title1)
      setTitle2(title2)
      setTitle3(title3)
      setDescription1(description1)
      setDescription2(description2)
      setDescription3(description3)
      setIcon1(logo1)
      setIcon2(logo2)
      setIcon3(logo3)
      setFooterImage(footerImage)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getBanners = async () => {
    setLoading(true)
    try {
      let response = await fetchBanners()
      setBanners(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const sendContact = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      await postContact({
        name: name,
        email: email,
        phone: phone,
        message: message,
        operation: operation,
      })
      setName('')
      setEmail('')
      setPhone('')
      setOperation('Quiero comprar')
      setMessage('')
      setShowModal(false)
      setLoading(false)
      toast(`Enviado con éxito`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    } catch (error: any) {
      setLoading(false)
      toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    }
  }

  return (
    <div>
      <ToastContainer />
      <Navigation />
      <Modal show={showModal} onHide={handleClose} size="lg">
        {loading && <Loading />}
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="row modal-information">
            <div className="col-12 col-md-6">
              <img src={agent} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col-12 col-md-6 form-contact">
              <p style={{ fontWeight: '600', textAlign: 'center', fontSize: '16px' }}>
                Te mantendré al tanto de la actualidad del mercado inmobiliario. <br />
                ¡No pierdas la oportunidad!
              </p>
              <form onSubmit={(e) => sendContact(e)}>
                <div className="item">
                  <label>* Nombre y Apellido</label>
                  <input
                    placeholder="Nombre y apellido"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 item">
                    <label>* Whatsapp</label>
                    <input
                      placeholder="Whatsapp"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 item">
                    <label>Correo electrónico</label>
                    <input
                      placeholder="Correo electrónico"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="item">
                  <label>* Operación</label>
                  <select value={operation} onChange={(e) => setOperation(e.target.value)} required>
                    <option value="Quiero alquilar">Quiero alquilar</option>
                    <option value="Quiero comprar">Quiero comprar</option>
                    <option value="Quiero vender">Quiero vender</option>
                    <option value="Quiero tasar">Quiero tasar</option>
                    <option value="Quiero hacer una consulta">Quiero hacer una consulta</option>
                  </select>
                </div>

                <div className="item">
                  <textarea
                    name="message"
                    cols={30}
                    rows={8}
                    required={true}
                    className="form-control"
                    placeholder="Mensaje"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <button type="submit" style={{ borderRadius: '4px' }}>
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6" style={{ marginTop: '1rem', display: 'flex' }}>
              <Link to="/comercial" className="modal-show-action-button">
                <img src={ic_comercial} alt="" width="25" style={{ marginRight: '0.5rem' }} />
                Ver propuesta comercial
              </Link>
            </div>
            <div className="col-12 col-md-6" style={{ marginTop: '1rem', display: 'flex' }}>
              <Link to="/propiedades" className="modal-show-action-button">
                <img src={ic_home} alt="" width="25" style={{ marginRight: '0.5rem' }} />
                Ver propiedades
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <BannerPrincipal />
      <BannerAgent />
      {banners.length ? (
        <Carousel autoplay className="carousel-banners">
          {banners.map((banner: any) => (
            <div>
              <Link to={banner.enlace}>
                <img src={`${Constants.BASE_URL}/${banner.image}`} alt="" />
              </Link>
            </div>
          ))}
        </Carousel>
      ) : null}
      <Testimonies />
      <MarkedProperties title="PROPIEDADES DESTACADAS" />
      <Footer />
    </div>
  )
}
