import React, { useLayoutEffect } from 'react'
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom'
import Agent from '../pages/agent'
import Contact from '../pages/contact'
import Growth from '../pages/growth'
import Home from '../pages/home'
import Properties from '../pages/properties'
import PropertyDetails from '../pages/propertyDetails'
import Business from '../pages/business'
import AboutUs from '../pages/aboutUs'
import Booking from '../pages/booking'

export default function AppRoutes() {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/propiedades/:id" element={<PropertyDetails />} />
      <Route path="/propiedades" element={<Properties />} />
      <Route path="/contacto" element={<Contact />} />
      {/*<Route path="/novedades/:id" element={<NewDetails />} />
      <Route path="/novedades" element={<News />} />*/}
      <Route path="/empresa" element={<Business />} />
      <Route path="/comercial" element={<Agent />} />
      <Route path="/desarrollo" element={<Growth />} />

      <Route path="/mercadolibre" element={<MercadoLibreRedirect />} />
      <Route path="/infocasas" element={<InfoCasasRedirect />} />

      <Route path="/agenda/:id" element={<Booking />} />
      <Route path="/agenda" element={<Booking />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

function MercadoLibreRedirect() {
  window.location.href = 'https://listado.mercadolibre.com.uy/inmuebles/_CustId_273421755'
  return null
}

function InfoCasasRedirect() {
  window.location.href =
    'https://www.infocasas.com.uy/inmobiliarias/493339-mgi-mario-mendez-gestion-inmobiliaria/propiedades'
  return null
}
