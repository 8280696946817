import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import { Constants } from '../../../Constants'

export default function PropertyItem({ col, item, isForBooking, bookingCallback }: any) {
  if (!isForBooking) {
    return (
      <div id="PropertyItem" className={`${col ? col : 'col-md-4'} col-12 item`}>
        <Link to={`/propiedades/${item.id}`}>
          <div className="item-container">
            <div className="item-image">
              <img src={item.medias.length ? item.medias[0].image : ''} alt="" />
              {item.sold || item.rented || item.reserved ? (
                <div className="sold-or-rented">
                  {item.sold ? 'Vendió' : item.rented ? 'Alquiló' : 'Reservó'}
                </div>
              ) : null}
            </div>
            <div className="item-details">
              <h6>{item.title}</h6>
              <p>
                {item.city.name}, {item.state.name}
              </p>
              <div style={{ display: 'flex' }}>
                {item.price > 0 ? (
                  <label>
                    {item.money.toUpperCase()} {item.price.toLocaleString('es-ES')}
                  </label>
                ) : null}
                <a href="/properties">Ver detalles</a>
              </div>
            </div>
            <div className="item-details-features">
              {item.data
                .filter((item: any) => item.checked)
                .slice(0, 3)
                .map((d: any) => (
                  <div className="item">
                    <img src={`${Constants.BASE_URL}/${d.icon}`} alt="" width="20" />
                    <span>
                      {d.realValue} {d.symbol ? d.symbol : ''}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </Link>
      </div>
    )
  }
  return (
    <div
      id="PropertyItem"
      className={`${col ? col : 'col-md-4'} col-12 item item-is-for-booking ${
        item.sold || item.rented || item.reserved ? 'disabled' : ''
      }`}
    >
      <div className="item-container" onClick={() => bookingCallback(item)}>
        <div className="item-image">
          <img src={item.medias.length ? item.medias[0].image : ''} alt="" />
          {item.sold || item.rented || item.reserved ? (
            <div className="sold-or-rented">
              {item.sold ? 'Vendió' : item.rented ? 'Alquiló' : 'Reservó'}
            </div>
          ) : null}
        </div>
        <div className="item-details is-for-booking">
          <h6>{item.title}</h6>
          <p>
            {item.city.name}, {item.state.name}
          </p>
          <div style={{ display: 'flex' }}>
            {item.price > 0 ? (
              <label>
                {item.money.toUpperCase()} {item.price.toLocaleString('es-ES')}
              </label>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
