import React, { useState, useEffect } from 'react'
import './styles.scss'
import { DatePicker, Input, Modal, Select } from 'antd'
import axios from 'axios'
import { Constants } from '../../Constants'
import VerticalCard from '../properties/vertical/VerticalCard'
import Pagination from '../pagination/Pagination'
import Loading from '../loading/Loading'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

export function BookingComponent({ isFromProperty = 0 }) {
  const [loading, setLoading] = useState(false)
  const [states, setStates] = useState<any[]>([])
  const [properties, setProperties] = useState([])
  const [pages, setPages] = useState(0)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<any>([])
  const [search, setSearch] = useState('')
  const [propertySelected, setPropertySelected] = useState<any>(null)
  const [showModalSelectProperty, setShowModalSelectProperty] = useState(false)
  const [loadingCreating, setLoadingCreating] = useState(false)
  const { id } = useParams()

  // data
  const [name, setName] = useState('')
  const [ci, setCi] = useState('')
  const [birthdateDay, setBirthdateDay] = useState<string | null>(null)
  const [birthdateMonth, setBirthdateMonth] = useState<string | null>(null)
  const [birthdateYear, setBirthdateYear] = useState<string | null>(null)
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [date, setDate] = useState<moment.Moment | null>(null)
  const [dateDisabled, setDateDisabled] = useState<any[]>([])
  const [hours, setHours] = useState([])
  const [hour, setHour] = useState<any>(null)

  useEffect(() => {
    getProperties()
    disabledDate()
  }, [])

  useEffect(() => {
    getProperties()
  }, [page, filters])

  useEffect(() => {
    fetchHours()
    setHour(null)
  }, [date])

  const getProperties = async (onlyStates = false, forceFirstPage = false, ignoreId = false) => {
    setLoading(true)
    try {
      if (forceFirstPage) {
        setPage(1)
      }
      let data = await fetchProperties(forceFirstPage ? 1 : page, ignoreId)
      setStates(states.length > 0 ? states : data.data.data.states)
      if (!onlyStates) {
        setProperties(data.data.data.properties)
        const result = data.data.data.properties
        if (!ignoreId && id) {
          const property = result.find((item: any) => item.id === parseInt(id))
          if (property) {
            setPropertySelected(property)
          }
        }
        if (isFromProperty) {
          const property = result.find((item: any) => item.id === isFromProperty)
          if (property) {
            setPropertySelected(property)
          }
        }
        setPages(data.data.data.pages)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchProperties = async (page: number, ignoreId = false) => {
    try {
      let idTmp = ignoreId ? null : id
      return await axios.post(`${Constants.BASE_URL}/properties/all_pagination?page=${page}`, {
        filters,
        search,
        growth: false,
        itemsPerPage: 8,
        id: idTmp,
      })
    } catch (error) {
      throw error
    }
  }

  const fetchHours = async () => {
    try {
      if (!date) return
      const response = await axios.get(
        `${Constants.BASE_URL}/bookings-date-available?date=${date?.format('YYYY-MM-DD')}`
      )
      setHours(response?.data?.data || [])
    } catch (error) {
      console.log(error)
    }
  }

  const disabledDate = async () => {
    try {
      const response = await axios.get(`${Constants.BASE_URL}/bookings-next-available`)
      let disabled = []
      for (const key in response?.data?.data || []) {
        if (response.data.data[key].some((item: any) => item.all_day_meeting)) {
          disabled.push(moment(key))
        }
      }
      setDateDisabled(disabled)
    } catch (error) {
      console.log(error)
    }
  }

  const onPrev = async () => {
    await setPage(page - 1)
  }

  const onNext = async () => {
    await setPage(page + 1)
  }

  const onPage = async (page: number) => {
    await setPage(page)
  }

  const saveBooking = async (e: any) => {
    e.preventDefault()
    try {
      if (!name) {
        toast.error('El campo nombre es requerido')
        return
      }
      if (!phone) {
        toast.error('El campo celular es requerido')
        return
      }
      if (!address) {
        toast.error('El campo dirección es requerido')
        return
      }
      if (!propertySelected) {
        toast.error('Seleccione una propiedad')
        return
      }
      if (!date) {
        toast.error('Seleccione una fecha')
        return
      }
      if (!hour) {
        toast.error('Seleccione una hora')
        return
      }

      let birthdate = null
      if (birthdateDay && birthdateMonth && birthdateYear) {
        birthdate = moment(`${birthdateYear}-${birthdateMonth}-${birthdateDay}`).format(
          'YYYY-MM-DD'
        )
      }
      setLoadingCreating(true)
      const data = await axios.post(`${Constants.BASE_URL}/bookings`, {
        name,
        ci,
        birthdate,
        phone,
        email,
        address,
        property_id: propertySelected.id,
        visit_date: date?.format('YYYY-MM-DD'),
        visit_hour: hour,
      })

      // set all fields to default
      setName('')
      setCi('')
      setBirthdateDay(null)
      setBirthdateMonth(null)
      setBirthdateYear(null)
      setPhone('')
      setEmail('')
      setAddress('')
      setDate(null)
      setHour(null)
      setLoadingCreating(false)
      toast.success('Visita agendada con éxito')
    } catch (error) {
      setLoadingCreating(false)
      toast.error('Error al agendar visita, vuelva a intentar')
    }
  }

  return (
    <div
      id="booking-component"
      className={`container ${isFromProperty ? 'no-modify-container' : ''}`}
    >
      {loadingCreating && <Loading />}

      <Modal
        open={showModalSelectProperty}
        width="80%"
        footer={null}
        onCancel={() => setShowModalSelectProperty(false)}
      >
        {loading && <Loading />}
        <div className="row">
          <div className="col-12">
            <h4>Seleccione la propiedad a visitar</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-12">
            <label>Buscar</label>
            <Input
              className="w-100"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => getProperties()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getProperties()
                }
              }}
              placeholder="Buscar por título"
            />
          </div>
          <div className="col-md-3 col-12">
            <label>Filtrar por departamento</label>
            <Select
              className="w-100"
              placeholder="Seleccione un departamento"
              onChange={(value) => {
                if (!value) {
                  setFilters([])
                  return
                }
                setFilters([
                  {
                    filter: value,
                    type: 'state',
                  },
                ])
              }}
              options={[
                {
                  value: '',
                  label: 'Todos',
                },
                ...(states.length > 0
                  ? states.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []),
              ]}
            />
          </div>
        </div>
        <div className="row">
          <VerticalCard
            properties={properties}
            pagination={
              <Pagination
                pages={pages}
                page={page}
                onPageProp={(page: number) => onPage(page)}
                onPrevProp={onPrev}
                onNextProp={onNext}
                marginTop="1rem"
              />
            }
            isForBooking={true}
            bookingCallback={(property: any) => {
              setPropertySelected(property)
              setShowModalSelectProperty(false)
            }}
          />
        </div>
      </Modal>
      {!isFromProperty ? (
        <div className="booking-header">
          <h3>MGI Mario Méndez Gestión Inmobiliaria</h3>
          <p>
            Registre su visita. Complete los datos requeridos e indique el día y horario que le
            quede bien visitar dicha propiedad.
          </p>
        </div>
      ) : null}
      <form className="booking-form" onSubmit={saveBooking}>
        <div className="form-group">
          <label htmlFor="name">Nombre y Apellido *</label>
          <Input
            required
            placeholder="Ingresar nombre y apellido"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="id">C.I.</label>
          <Input value={ci} onChange={(e) => setCi(e.target.value)} placeholder="Ingresar CI" />
        </div>
        <div className="form-group">
          <label htmlFor="birthdate">Fecha de nacimiento</label>
          <div className="d-flex" style={{ gap: '0.25rem', alignItems: 'center' }}>
            <Select placeholder="Día" value={birthdateDay} onChange={(e) => setBirthdateDay(e)}>
              {[...Array(31)].map((_, index) => (
                <Select.Option key={index + 1} value={index + 1}>
                  {index + 1}
                </Select.Option>
              ))}
            </Select>
            <label>/</label>
            <Select placeholder="Mes" value={birthdateMonth} onChange={(e) => setBirthdateMonth(e)}>
              {[...Array(12)].map((_, index) => (
                <Select.Option key={index + 1} value={index + 1}>
                  {index === 0
                    ? 'Enero'
                    : index === 1
                    ? 'Febrero'
                    : index === 2
                    ? 'Marzo'
                    : index === 3
                    ? 'Abril'
                    : index === 4
                    ? 'Mayo'
                    : index === 5
                    ? 'Junio'
                    : index === 6
                    ? 'Julio'
                    : index === 7
                    ? 'Agosto'
                    : index === 8
                    ? 'Septiembre'
                    : index === 9
                    ? 'Octubre'
                    : index === 10
                    ? 'Noviembre'
                    : 'Diciembre'}
                </Select.Option>
              ))}
            </Select>
            <label>/</label>
            <Select placeholder="Año" value={birthdateYear} onChange={(e) => setBirthdateYear(e)}>
              {
                // mostrar 80 años para atras desde el año actual - 18
              }
              {[...Array(80)].map((_, index) => (
                <Select.Option key={index + 1} value={moment().year() - 18 - index}>
                  {moment().year() - 18 - index}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Celular *</label>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            placeholder="Ingresar número de celular"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ingresar email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Dirección personal *</label>
          <Input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder="Ingresar dirección personal"
          />
        </div>
        {!isFromProperty ? (
          <div className="form-group">
            <label htmlFor="property">Propiedad a visitar *</label>
            <Select
              placeholder="Seleccione una propiedad"
              onClick={() => {
                getProperties(false, true, true)
                setShowModalSelectProperty(true)
              }}
              value={
                propertySelected
                  ? { value: propertySelected.id, label: propertySelected.title }
                  : null
              }
              notFoundContent={null}
            />
          </div>
        ) : null}
        <div className="form-group">
          <label htmlFor="date">Fecha *</label>
          <DatePicker
            placeholder="Seleccionar fecha"
            value={date}
            onChange={(date, dateString) => setDate(date)}
            format={'DD/MM/YYYY'}
            disabledDate={(current) =>
              (current && current < moment().startOf('day')) ||
              current > moment().add(30, 'days') ||
              dateDisabled.some((date) => date.isSame(current, 'day'))
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Hora *</label>
          <Select
            placeholder="Seleccione hora"
            value={hour}
            onChange={(value) => setHour(value)}
            notFoundContent={
              <div style={{ padding: '1rem 0.5rem' }}>
                No hay horas disponibles, seleccione otro día
              </div>
            }
            options={hours
              .filter((item: any) => moment(item.from) > moment())
              .map((item: any) => ({
                value: moment(item.from).format('H:mm'),
                label: moment(item.from).format('H:mm'),
                disabled: !item.available,
              }))}
          />
        </div>
        <button type="submit" className="submit-button">
          ENVIAR
        </button>
      </form>
    </div>
  )
}
