import Footer from '../../components/footer/Footer'
import Navigation from '../../components/navigation/Navigation'
import './styles.scss'
import { BookingComponent } from '../../components/booking'

export default function Booking() {
  return (
    <div>
      <Navigation />
      <BookingComponent />
      <Footer hideSubscribe/>
    </div>
  )
}
