import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import ReactStreetview from 'react-streetview'

const googleMapsApiKey = 'AIzaSyBRqcabWXM6tmqV36R_x_r_6G4zi4gNU-Y'

const GoogleMap = ({ lat, lng, type = 1, height }: any) => {
  return (
    <>
      {type === 1 ? (
          <Map
            google={window.google}
            style={{ maxWidth: '100%' }}
            initialCenter={{
              lat,
              lng,
            }}
            streetViewControl={false}
            mapTypeControlOptions={{ position: 9 }}
          >
            <Marker position={{ lat, lng }} title="MGI Mario Méndez Gestión Inmobiliaria" />
          </Map>
      ) : (
        <>
          <div
            style={{
              backgroundColor: '#eeeeee',
              height,
            }}
          >
            <ReactStreetview
              apiKey={googleMapsApiKey}
              streetViewPanoramaOptions={{
                position: { lat: Number(lat), lng: Number(lng) },
                pov: { heading: 100, pitch: 0 },
                zoom: 1,
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: googleMapsApiKey,
})(GoogleMap)
