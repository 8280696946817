const APP_DEVELOPER = false

const BASE_URL_PRODUCTION = 'https://mariomendez.uy/api/'
const BASE_URL_LOCAL = 'http://localhost:8888/mario_mendez_api/'

export const Constants = {
  GENERIC_ERROR: 'Ocurrió un error al procesar la información, reintetar',
  HASH_USER_SESSION: 'z8fynsq4vDsCKkEMXj6ZPA67xX2sYdBA',
  KEY_USER_SESSION: '5zxjyVTLX5ygvejQjkvRBsMPxG3FUCRR',
  FILTER_CATEGORY: 'category',
  FILTER_STATE: 'state',
  FILTER_BEDROOM: 'bedroom',
  FILTER_BATHROOM: 'bathroom',
  FILTER_OPERATION: 'operation',
  FILTER_TAGS: 'tags',
  BASE_URL: APP_DEVELOPER ? BASE_URL_LOCAL : BASE_URL_PRODUCTION,
}
