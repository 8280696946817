import React, { Component, useState } from 'react'
import Loading from '../loading/Loading'
import './FormContact.scss'
import phoneIcon from '../../assets/icons/mm_ic_phone.svg'
import wpp from '../../assets/icons/mm_ic_wpp_white.svg'
import { postContact } from '../../services/SharedServices'
import { toast, ToastContainer } from 'react-toastify'

export default function FormContact({ propertyUrl }: any) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const sendContact = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      await postContact({
        name,
        email,
        phone,
        message,
        property_id: propertyUrl,
      })
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
      setLoading(false)
      toast(`Consulta enviada con éxito`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    } catch (error: any) {
      setLoading(false)
      toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    }
  }

  return (
    <div>
      {loading && <Loading />}
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className={`form-contact pt-1`}>
            <form onSubmit={(e) => sendContact(e)}>
              <div className="form-group">
                <label>* Nombre:</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  className="form-control"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>* Teléfono:</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>* Consulta:</label>
                <textarea
                  className="form-control"
                  rows={2}
                  required
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div
                className="input-group button-send"
                style={{ textAlign: 'center', paddingTop: '1rem' }}
              >
                <button
                  type="submit"
                  style={{
                    width: '100%',
                    borderRadius: '3px',
                    height: '40px',
                    background: '#003399',
                  }}
                >
                  Enviar consulta
                </button>
                <div className="row">
                  <div className="col-6" style={{ paddingLeft: '0rem' }}>
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        marginTop: '1rem',
                        borderRadius: '3px',
                        height: '40px',
                        background: '#2ad366',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0rem',
                      }}
                    >
                      <img src={wpp} alt="" style={{ marginRight: '0.25rem' }} />
                      Whatsapp
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        marginTop: '1rem',
                        borderRadius: '3px',
                        height: '40px',
                        background: 'rgb(48 52 65)',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0rem',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={phoneIcon} alt="" style={{ marginRight: '0.25rem' }} width="22" />{' '}
                      Teléfono
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
