import React, { useState, useEffect } from 'react'
import GoogleMap from './googleMaps/GoogleMap'
import iconPhone from '../../assets/icons/phone-solid-dark.svg'
import iconEnvelope from '../../assets/icons/envelope-solid-dark.svg'
import { fetchAccount } from '../../services/SharedServices'
import iconLocation from '../../assets/icons/map-marker-alt-solid.svg'
import axios from 'axios'
import { Constants } from '../../Constants'
import Loading from '../loading/Loading'
import './Contact.scss'
import ic_facebook from '../../assets/icons/mm_ic_facebook.svg'
import ic_youtube from '../../assets/icons/mm_ic_youtube.svg'
import ic_wpp from '../../assets/icons/mm_ic_wpp.svg'
import ic_twitter from '../../assets/icons/mm_ic_twitter.svg'
import ic_linkedin from '../../assets/icons/mm_ic_linkedin.svg'
import ic_instagram from '../../assets/icons/mm_ic_instagram.svg'
import ic_calendar from '../../assets/icons/calendar_blue.svg'

const instance = axios.create({ baseURL: Constants.BASE_URL })

type Direction = {
  id?: number
  nombre: string
  direccion: string
  telefono: string
  horario: string
  imagen: string
  email: string
  lat: string
  lng: string
}

export default function Offices() {
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [telegram, setTelegram] = useState('')
  const [twitter, setTwitter] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [youtube, setYoutube] = useState('')
  const [loading, setLoading] = useState(false)
  const [directions, setDirections] = useState<Direction[] | null>(null)

  const getAccount = async () => {
    try {
      let response = await fetchAccount()
      const { facebook, instagram, linkedin, telegram, twitter, whatsapp, youtube } =
        response.data.data.config
      setFacebook(facebook)
      setInstagram(instagram)
      setLinkedin(linkedin)
      setTelegram(telegram)
      setTwitter(twitter)
      setWhatsapp(whatsapp)
      setYoutube(youtube)
    } catch (error) {}
  }

  const fetchDirections = async () => {
    setLoading(true)
    try {
      const res = await instance.get(`/directions`)
      setDirections(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  useEffect(() => {
    getAccount()
  }, [])

  useEffect(() => {
    fetchDirections()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <div className="container d-block">
        {directions?.map((d) => {
          return (
            <div id="offices" key={d.id} className="row col-sm-12" style={{ margin: 10 }}>
              <div
                className="col-12 flex-column flex-sm-row"
                style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
              >
                {d.imagen ? (
                  <div className="col-sm-4 col-12" style={{ border: '1px solid #e7e7e7' }}>
                    <img
                      style={{ display: 'flex', maxWidth: '100%' }}
                      src={`${Constants.BASE_URL}${d.imagen}`}
                      alt=""
                    />
                  </div>
                ) : null}
                <div
                  className={`${d.imagen ? 'col-sm-4' : 'col-sm-5'} col-12`}
                  style={{
                    display: 'flex',
                    gap: '0.75rem',
                    textAlign: 'left',
                    flexDirection: 'column',
                    padding: '0px 15px',
                  }}
                >
                  <h3>{d.nombre}</h3>
                  <div className="item" style={{ display: 'flex', alignItems: 'start' }}>
                    <img src={iconLocation} alt="" />
                    &nbsp;&nbsp;
                    <p style={{ marginBottom: '0px' }}>{d.direccion}</p>
                  </div>
                  <div className="item">
                    <img src={iconPhone} alt="" />
                    &nbsp;&nbsp;
                    <span style={{ color: '#003399' }}>
                      <a href={`tel:+59891999222`}>{d.telefono} </a>
                    </span>
                  </div>
                  <div className="item">
                    <img src={iconEnvelope} alt="" />
                    &nbsp;&nbsp;
                    <span style={{ color: '#003399' }}>
                      <a href={d.email}>{d.email}</a>
                    </span>
                  </div>
                  <div className="item" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={ic_calendar} alt="" />
                    &nbsp;&nbsp;
                    <span style={{ color: '#003399' }}>
                      <a href={`https://mariomendez.uy/agenda`} target="_blank" rel="noreferrer">
                        Agendar visita
                      </a>
                    </span>
                  </div>

                  <div
                    className="item"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 5,
                      textAlign: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {facebook ? (
                      <a target="_blank" href={facebook} rel="noreferrer">
                        <img
                          src={ic_facebook}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                    {instagram ? (
                      <a target="_blank" href={instagram} rel="noreferrer">
                        <img
                          src={ic_instagram}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                    {linkedin ? (
                      <a target="_blank" href={linkedin} rel="noreferrer">
                        <img
                          src={ic_linkedin}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                    {/*telegram ? (
                      <a target="_blank" href={telegram} rel="noreferrer">
                         <img
                          src={ic_telegram}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                        ) : null*/}
                    {twitter ? (
                      <a target="_blank" href={twitter} rel="noreferrer">
                        <img
                          src={ic_twitter}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                    {whatsapp ? (
                      <a target="_blank" href={whatsapp} rel="noreferrer">
                        <img
                          src={ic_wpp}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                    {youtube ? (
                      <a target="_blank" href={youtube} rel="noreferrer">
                        <img
                          src={ic_youtube}
                          alt=""
                          style={{
                            width: 'auto',
                            cursor: 'pointer',
                            height: '24px',
                            marginRight: '0.5rem',
                          }}
                        />
                      </a>
                    ) : null}
                  </div>

                  {d.horario ? (
                    <div className="item" style={{ fontSize: 15 }}>
                      <small>Horario: {d.horario} </small>
                    </div>
                  ) : null}
                </div>
                <div className={`${d.imagen ? 'col-sm-4' : 'col-sm-7'} col-12`}>
                  <div
                    id="map-container"
                    style={{
                      position: 'relative',
                      display: 'flex',
                      maxWidth: '100%',
                      height: '290px',
                      margin: 0,
                    }}
                  >
                    <GoogleMap lat={d.lat} lng={d.lng} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
