import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../components/footer/Footer'
import Loading from '../../components/loading/Loading'
import Navigation from '../../components/navigation/Navigation'
import './styles.scss'
import ImageGallery from 'react-image-gallery'
import FormContact from '../../components/contact/FormContact'
import ic_email from '../../assets/icons/mm_ic_email_blue.svg'
import ic_facebook from '../../assets/icons/mm_ic_facebook_blue.svg'
import ic_wpp from '../../assets/icons/mm_ic_wpp_blue.svg'
import ic_twitter from '../../assets/icons/mm_ic_twitter_blue.svg'
import ic_linkedin from '../../assets/icons/mm_ic_linkedin_blue.svg'
import { useParams } from 'react-router-dom'
import { Constants } from '../../Constants'
import GoogleMap from '../../components/contact/googleMaps/GoogleMap'
import ic_location from '../../assets/icons/map-marker-alt-solid-white.svg'
import ic_camera from '../../assets/icons/camera.svg'
import ReactPlayer from 'react-player'
import { Tabs } from 'antd'
import { BookingComponent } from '../../components/booking'

export default function PropertyDetails(props: any) {
  let data: any = null
  const [property, setProperty] = useState(data)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [tab, setTab] = useState('1')

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      try {
        let { data } = await getProperty()
        if (data.data.youtube) {
          data.data.medias.push({
            youtube: data.data.youtube,
            image: `public/video_thumb2.png`,
          })
        }
        setProperty(data.data)
        setLoading(false)
        window.scrollTo(0, 0)
      } catch (error) {
        setLoading(false)
      }
    }

    init()
  }, [])

  const getProperty = async () => {
    try {
      return await axios.get(`${Constants.BASE_URL}/properties/${id}`)
    } catch (error) {
      throw error
    }
  }

  const [showType, setShowType] = useState(1)
  const [heightGallerySpace, setHeightGallerySpace] = useState(0)
  const ref = useRef<any>(null)

  return (
    <div id="PropertyDetails">
      {loading && <Loading />}
      <Navigation />
      {property !== null ? (
        <div className="background-grey">
          <div className="container">
            <div className="row separated">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-85">
                    <h4 className="secondary-font">{property.title}</h4>
                    <p style={{ fontSize: '14px' }}>
                      {property.city.name}, {property.state.name}
                    </p>
                    <div className="products-page-gallery">
                      <div ref={ref} className="product-page-gallery-main">
                        <div
                          style={{
                            marginTop: showType === 3 ? '5rem' : '1rem',
                          }}
                          className="buttons-options"
                        >
                          {showType !== 1 ? (
                            <button onClick={() => setShowType(1)}>
                              <img src={ic_camera} alt="" />
                              Ver Fotos
                            </button>
                          ) : null}
                          {showType !== 2 && property.location_lat && property.location_lon ? (
                            <button onClick={() => setShowType(2)}>
                              <img src={ic_location} alt="" />
                              Ver Ubicación
                            </button>
                          ) : null}
                          {showType !== 3 && property.location_lat && property.location_lon ? (
                            <button onClick={() => setShowType(3)}>
                              <img src={ic_location} alt="" />
                              Ver calle 3D
                            </button>
                          ) : null}
                        </div>
                        {showType === 1 ? (
                          <div className="item-image">
                            <ImageGallery
                              items={property.medias.map(({ image, youtube }: any) => ({
                                original: `${Constants.BASE_URL}${image}`,
                                thumbnail: `${Constants.BASE_URL}${image}`,
                                ...(youtube && {
                                  renderItem: () => (
                                    <ReactPlayer
                                      controls={true}
                                      url={youtube}
                                      width="100%"
                                      height={heightGallerySpace + 'px'}
                                    />
                                  ),
                                }),
                              }))}
                              showPlayButton={false}
                              onImageLoad={() =>
                                setHeightGallerySpace(
                                  ref.current.clientHeight -
                                    document.getElementsByClassName(
                                      'thumbnails-swipe-horizontal'
                                    )[0].clientHeight
                                )
                              }
                            />
                            {property.sold || property.rented || property.reserved ? (
                              <div className="sold-or-rented">
                                {property.sold ? 'Vendió' : property.rented ? 'Alquiló' : 'Reservó'}
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {(showType === 2 || showType === 3) &&
                        property.location_lat &&
                        property.location_lon ? (
                          <div
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: heightGallerySpace + 'px',
                            }}
                          >
                            <GoogleMap
                              lat={property.location_lat}
                              lng={property.location_lon}
                              type={showType === 2 ? 1 : 2}
                              height={heightGallerySpace + 'px'}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {property.price > 0 ? (
                      <div className="property-price">
                        <div className="price">
                          <span className="operation-type">
                            {property.operation_type === 1 ? 'VENTA' : 'ALQUILER'}
                          </span>
                          <span className="price-value">
                            {`${property.money.toUpperCase()} ${new Intl.NumberFormat(
                              'de-DE'
                            ).format(property.price)}`}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {property.data.length ? (
                      <>
                        <div className="ficha-tecnica">
                          <h4 className="secondary-font" style={{ marginBottom: '2rem' }}>
                            Ficha Técnica
                          </h4>
                          <div className="row items">
                            {property.data.map((item: any) => (
                              <React.Fragment>
                                <div className="col-12 col-md-4">
                                  <span className="secondary-font bold">{item.value}</span>
                                  <span className="count-feature secondary-font">
                                    {item.realValue} {item.symbol}
                                  </span>
                                  <hr />
                                </div>
                                <div className="col-md-2"></div>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div
                    className="col-12 col-md-35"
                    style={{ marginTop: '74px', paddingLeft: '0px' }}
                  >
                    <div className="desktop">
                      <div
                        className=" separated information-contact"
                        style={{ height: 'max-content' }}
                      >
                        <Tabs
                          onChange={(tab) => setTab(tab)}
                          type="card"
                          activeKey={tab}
                          defaultActiveKey="1"
                          items={[
                            {
                              label: `Consultar`,
                              key: '1',
                              children: (
                                <>
                                  <FormContact
                                    propertyUrl={`https://mariomendez.uy/propiedades/${id}`}
                                  />
                                  <div className="icons-shared">
                                    <label>Compartir en:</label>
                                    <div className="content-shared">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`mailto:info@example.com?&subject=&cc=&bcc=&body=https://mariomendez.uy/propiedades/${id}%0A`}
                                      >
                                        <img src={ic_email} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://api.whatsapp.com/send?text=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_wpp} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_facebook} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://twitter.com/intent/tweet?url=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_twitter} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_linkedin} alt="" width="25" />
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ),
                            },
                            {
                              label: `Agendar visita`,
                              key: '2',
                              children: <BookingComponent isFromProperty={property.id} />,
                            },
                          ]}
                        />
                      </div>
                      {property.similarProperties.length ? (
                        <div className="simiar-properties" style={{ marginTop: '4rem' }}>
                          <span>PROPIEDADES SIMILARES</span>
                          <div style={{ marginTop: '1.5rem', display: 'grid' }}>
                            {property.similarProperties.map((similarProperty: any) => (
                              <a
                                href={`/propiedades/${similarProperty.id}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginBottom: '1rem', display: 'flex' }}
                              >
                                <img
                                  src={`${Constants.BASE_URL}${similarProperty.medias?.image}`}
                                  alt=""
                                  width={100}
                                />
                                <div style={{ marginLeft: '0.5rem', display: 'grid' }}>
                                  <p style={{ fontSize: '14px', marginBottom: 'auto' }}>
                                    {similarProperty.title}
                                  </p>
                                  {similarProperty.data.length ? (
                                    <div style={{ display: 'flex' }}>
                                      {similarProperty.data
                                        .filter((item: any) => item.checked)
                                        .slice(0, 3)
                                        .map((item: any, index: number) => (
                                          <div style={{ marginRight: '0.5rem' }}>
                                            <img
                                              src={`${Constants.BASE_URL}/${item.icon}`}
                                              alt=""
                                              width="25"
                                            />
                                            <span style={{ fontSize: '14px' }}>
                                              {item.realValue} {item.symbol ? item.symbol : ''}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                  ) : null}
                                  {similarProperty.price > 0 ? (
                                    <p
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      {`${similarProperty.money.toUpperCase()} ${new Intl.NumberFormat(
                                        'de-DE'
                                      ).format(similarProperty.price)}`}
                                    </p>
                                  ) : null}
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {property.data.length && <hr />}
                <div className="property-description">
                  <h4 className="secondary-font">Descripción de la propiedad</h4>
                  <div
                    className="secondary-font"
                    dangerouslySetInnerHTML={{ __html: property.description }}
                  />
                  {property.is_growth && property.propertiesParsed?.length ? (
                    <div className="table-growth">
                      {(property.propertiesParsed || []).map((item: any) => (
                        <div>
                          <img src={item.medias?.imageUrl || ''} alt="" />
                          <table>
                            <tr>
                              <th>Dormitorios</th>
                              <td>
                                {item.data.reduce((acc: any, item: any) => {
                                  return item.id === 16 ? item.realValue : acc
                                }, 0)}
                              </td>
                            </tr>
                            <tr>
                              <th>M2</th>
                              <td>
                                {item.data.reduce((acc: any, item: any) => {
                                  return item.id === 14 ? item.realValue : acc
                                }, 0)}{' '}
                                m<sup>2</sup>
                              </td>
                            </tr>
                            <tr>
                              <th>Precio</th>
                              <td>
                                {item.operation_type === 1 ? 'Venta' : 'Alquiler'}{' '}
                                {`${item.money.toUpperCase()} ${new Intl.NumberFormat(
                                  'de-DE'
                                ).format(item.price)}`}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <button
                                  className="btn"
                                  onClick={() => (window.location.href = `/propiedades/${item.id}`)}
                                >
                                  Ver unidad
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {property.tags.length ? (
                  <>
                    <hr />
                    <div className="ficha-tecnica">
                      <h4 className="secondary-font" style={{ marginBottom: '2rem' }}>
                        {property.featureOption || 'Características'}
                      </h4>
                      <div className="row items">
                        {property.tags.map((item: any) => (
                          <React.Fragment>
                            <div className="col-12 col-md-4">
                              <span className="secondary-font bold">{item.value}</span>
                              <hr />
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="mobile">
                  <div className="row">
                    <div className="col-12 col-md-35">
                      <div
                        className=" separated information-contact"
                        style={{ height: 'max-content' }}
                      >
                        <Tabs
                          onChange={(tab) => setTab(tab)}
                          type="card"
                          activeKey={tab}
                          defaultActiveKey="1"
                          items={[
                            {
                              label: `Consultar`,
                              key: '1',
                              children: (
                                <>
                                  <FormContact
                                    propertyUrl={`https://mariomendez.uy/propiedades/${id}`}
                                  />
                                  <div className="icons-shared">
                                    <label>Compartir en:</label>
                                    <div className="content-shared">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`mailto:info@example.com?&subject=&cc=&bcc=&body=https://mariomendez.uy/propiedades/${id}%0A`}
                                      >
                                        <img src={ic_email} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://api.whatsapp.com/send?text=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_wpp} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_facebook} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://twitter.com/intent/tweet?url=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_twitter} alt="" width="25" />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://mariomendez.uy/propiedades/${id}`}
                                      >
                                        <img src={ic_linkedin} alt="" width="25" />
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ),
                            },
                            {
                              label: `Agendar visita`,
                              key: '2',
                              children: <BookingComponent isFromProperty={property.id} />,
                            },
                          ]}
                        />
                      </div>
                      {property.similarProperties.length ? (
                        <div className="simiar-properties" style={{ marginTop: '4rem' }}>
                          <span>PROPIEDADES SIMILARES</span>
                          <div style={{ marginTop: '1.5rem', display: 'grid' }}>
                            {property.similarProperties.map((similarProperty: any) => (
                              <a
                                href={`/propiedades/${similarProperty.id}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginBottom: '1rem', display: 'flex' }}
                              >
                                <img
                                  src={`${Constants.BASE_URL}${similarProperty.medias?.image}`}
                                  alt=""
                                  width={100}
                                />
                                <div style={{ marginLeft: '0.5rem', display: 'grid' }}>
                                  <p style={{ fontSize: '14px', marginBottom: 'auto' }}>
                                    {similarProperty.title}
                                  </p>
                                  {similarProperty.data.length ? (
                                    <div style={{ display: 'flex' }}>
                                      {similarProperty.data
                                        .filter((item: any) => item.checked)
                                        .slice(0, 3)
                                        .map((item: any, index: number) => (
                                          <div style={{ marginRight: '0.5rem' }}>
                                            <img
                                              src={`${Constants.BASE_URL}/${item.icon}`}
                                              alt=""
                                              width="25"
                                            />
                                            <span style={{ fontSize: '14px' }}>
                                              {item.realValue} {item.symbol ? item.symbol : ''}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                  ) : null}
                                  {similarProperty.price > 0 ? (
                                    <p
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      {`${similarProperty.money.toUpperCase()} ${new Intl.NumberFormat(
                                        'de-DE'
                                      ).format(similarProperty.price)}`}
                                    </p>
                                  ) : null}
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </div>
  )
}
