import React, { useState, useEffect } from 'react'
import ContactForm from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import Navigation from '../../components/navigation/Navigation'
import './styles.scss'
import { Constants } from '../../Constants'
import axios from 'axios'

const instance = axios.create({ baseURL: Constants.BASE_URL })

const fetchBrokers = async () => {
  try {
    return await instance.get(`/brokers?type=contact_directions`)
  } catch (error) {
    throw error
  }
}

export default function Contact() {
  const [bannerDesktop, setBannerDesktop] = useState('')
  const [bannerMobile, setBannerMobile] = useState('')

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      let response = await fetchBrokers()
      const { banner_desktop, banner_mobile } = response.data.data
      setBannerDesktop(banner_desktop)
      setBannerMobile(banner_mobile)
    } catch (error) {}
  }

  return (
    <div style={{ marginBottom: '4rem' }}>
      <div
        className="col-12 text-center d-none d-md-block"
        style={{ marginTop: 60, paddingRight: '0px' }}
      >
        <img
          src={`${Constants.BASE_URL}${bannerDesktop}`}
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <div className="col-12 d-block d-md-none" style={{ marginTop: 50, paddingRight: '0px' }}>
        <img
          src={`${Constants.BASE_URL}${bannerMobile || bannerDesktop}`}
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <Navigation />
      <div className="container">
        <ContactForm />
      </div>
      <Footer />
    </div>
  )
}
