import React from 'react'
import './Loading.scss'
import logo from '../../assets/images/LOGO MGI blanco rounded.png'

export default function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-content">
        <img src={logo} className="image" alt="" />
      </div>
    </div>
  )
}
