import axios from 'axios'
import { Constants } from '../Constants'

const instance = axios.create({ baseURL: Constants.BASE_URL })

export const fetchHome = async () => {
  try {
    return await instance.get(`/home`)
  } catch (error) {
    throw error
  }
}

export const fetchBanners = async () => {
  try {
    return await instance.get(`/banners`)
  } catch (error) {
    throw error
  }
}

export const fetchMembers = async () => {
  try {
    return await instance.get(`/members`)
  } catch (error) {
    throw error
  }
}
