/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/Footer'
import Loading from '../../components/loading/Loading'
import Navigation from '../../components/navigation/Navigation'
import Pagination from '../../components/pagination/Pagination'
import VerticalCard from '../../components/properties/vertical/VerticalCard'
import './styles.scss'
import clear from '../../assets/icons/clear-24-px.svg'
import trash from '../../assets/icons/delete.svg'
import done from '../../assets/icons/done-24px.svg'
import closeBlue from '../../assets/icons/close_blue.svg'
import { Constants } from '../../Constants'
import filterIcon from '../../assets/icons/filter.svg'
import useNearScreen from '../../hooks/useNearScreen'

const MONEY = ['usd', 'uyu']

export default function Properties() {
  const initArray: any[] = []
  const initArrayStates: any[] = []
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const [count, setCount] = useState(0)
  const [properties, setProperties] = useState([])
  const [filters, setFilters] = useState(initArray)
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState([
    { id: 1, name: 'Apartamentos', selected: false },
    { id: 2, name: 'Campos', selected: false },
    { id: 3, name: 'Casas', selected: false },
    { id: 4, name: 'Terrenos', selected: false },
    { id: 5, name: 'Otros', selected: false },
    { id: 6, name: 'Locales Comerciales', selected: false },
    { id: 7, name: 'Oficinas', selected: false },
    { id: 8, name: 'Chacras', selected: false },
    { id: 9, name: 'Galpónes', selected: false },
    { id: 10, name: 'Logísticos', selected: false },
    { id: 11, name: 'Hotel', selected: false },
    { id: 12, name: 'Posada', selected: false },
  ])
  const [states, setStates] = useState(initArrayStates)
  const [bedrooms, setBedrooms] = useState([
    { id: 1, name: '1', selected: false },
    { id: 2, name: '2', selected: false },
    { id: 3, name: '3', selected: false },
    { id: 4, name: '4', selected: false },
    { id: 5, name: '5+', selected: false },
  ])
  const [bathrooms, setBathrooms] = useState([
    { id: 1, name: '1', selected: false },
    { id: 2, name: '2', selected: false },
    { id: 3, name: '3', selected: false },
    { id: 4, name: '4', selected: false },
    { id: 5, name: '5+', selected: false },
  ])
  const [operations, setOperations] = useState([
    { selected: false, name: 'Venta' },
    { selected: false, name: 'Alquiler' },
  ])
  const [tags, setTags] = useState([{ id: 37, name: 'Cochera / Garage', selected: false }])
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [first, setFirst] = useState(true)
  const [first2, setFirst2] = useState(true)
  const [sales, setSales] = useState(0)
  const [rents, setRents] = useState(0)
  const [orderBy, setOrderBy] = useState('last-ingress')
  const [bannerDesktop, setBannerDesktop] = useState('')
  const [bannerMobile, setBannerMobile] = useState('')
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const { isNearScreen, elementRef } = useNearScreen({ distance: '-120px', once: false })
  const [currency, setCurrency] = useState('usd')
  const [prices, setPrices] = useState({
    min: '',
    max: '',
    currency: '',
  })
  const [showMoreProperties, setShowMoreProperties] = useState(false)

  const instance = axios.create({ baseURL: Constants.BASE_URL })

  const fetchPropertiesBanner = async () => {
    try {
      return await instance.get(`/brokers?type=properties`)
    } catch (error) {
      throw error
    }
  }

  const fetch = async () => {
    try {
      let response = await fetchPropertiesBanner()
      const { banner_desktop, banner_mobile } = response.data.data
      setBannerDesktop(banner_desktop)
      setBannerMobile(banner_mobile)
    } catch (error) {}
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.innerWidth])

  useEffect(() => {
    if (isNearScreen) {
      setPage(() => page + 1)
    }
  }, [isNearScreen])

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    getProperties(true)
  }, [])

  useEffect(() => {
    if (first2 && states.length > 0) {
      init()
      setFirst2(false)
    }
  }, [states])

  const init = async () => {
    const newFilters: any = []
    const newFiltersTmp = []
    if (sessionStorage.getItem('comeFromSearch')) {
      sessionStorage.removeItem('filters')
      sessionStorage.removeItem('comeFromSearch')
    }
    if (sessionStorage.getItem('filters')) {
      let filters = JSON.parse(sessionStorage.getItem('filters') || '')
      for (let filter of filters) {
        await applyFilterFromStorage(filter)
        newFiltersTmp.push({
          item: { id: filter.filter, name: filter.name, selected: false },
          text: '',
          type: filter.type,
        })
      }
    }
    if (sessionStorage.getItem('category')) {
      let filters = JSON.parse(sessionStorage.getItem('category') || '')
      for (let filter of filters) {
        await applyFilterFromStorage(filter)
        newFiltersTmp.push({
          item: { id: filter.filter, name: filter.name, selected: false },
          text: '',
          type: filter.type,
        })
      }
    }
    if (sessionStorage.getItem('tags')) {
      let filters = JSON.parse(sessionStorage.getItem('tags') || '')
      for (let filter of filters) {
        await applyFilterFromStorage(filter)
        newFiltersTmp.push({
          item: { id: filter.filter, name: filter.name, selected: false },
          text: '',
          type: filter.type,
        })
      }
    }
    if (sessionStorage.getItem('search') !== null && sessionStorage.getItem('search') !== '') {
      await setSearch(sessionStorage.getItem('search') || '')
    }
    if (sessionStorage.getItem('type')) {
      if (sessionStorage.getItem('type') === '2') {
        await applyFilterFromStorage({ name: 'Alquiler', type: 'operation', filter: 'Alquiler' })
        newFiltersTmp.push({
          item: { selected: false, name: 'Alquiler', id: 2 },
          text: '',
          type: Constants.FILTER_OPERATION,
        })
      } else {
        await applyFilterFromStorage({ name: 'Venta', type: 'operation', filter: 'Venta' })
        newFiltersTmp.push({
          item: { selected: false, name: 'Venta', id: 1 },
          text: '',
          type: Constants.FILTER_OPERATION,
        })
      }
    }
    const filtersTmp = filters
    for (let newFilter of newFiltersTmp) {
      let filter: any
      if (
        newFilter.type === Constants.FILTER_CATEGORY ||
        newFilter.type === Constants.FILTER_STATE ||
        newFilter.type === Constants.FILTER_TAGS
      ) {
        filter = {
          name: `${newFilter.item.name} ${newFilter.text}`,
          type: newFilter.type,
          filter: newFilter.item.id,
        }
      } else {
        filter = {
          name: `${newFilter.item.name} ${newFilter.text}`,
          type: newFilter.type,
          filter: newFilter.item.name,
        }
      }
      if (filtersTmp.filter((item) => item.name.trim() === filter.name.trim()).length === 0) {
        newFilters.push(filter)
        filtersTmp.push(filter)
      }
    }
    sessionStorage.removeItem('filters')
    await sessionStorage.removeItem('type')
    await sessionStorage.removeItem('search')
    await sessionStorage.removeItem('category')
    await sessionStorage.removeItem('tags')
    setFilters(newFilters)
  }

  useEffect(() => {
    if (
      !sessionStorage.getItem('type') &&
      !sessionStorage.getItem('search') &&
      !sessionStorage.getItem('category') &&
      !sessionStorage.getItem('tags')
    ) {
      getProperties()
    }
  }, [page, filters, orderBy])

  useEffect(() => {
    if (filters.length > 0) {
      sessionStorage.setItem('filters', JSON.stringify(filters))
    }
  }, [filters])

  useEffect(() => {
    setPrices({
      min: minPrice,
      max: maxPrice,
      currency,
    })
  }, [minPrice, maxPrice, currency])

  const getProperties = async (onlyStates = false) => {
    setLoading(true)
    try {
      if (isMobile) {
        let data = await fetchProperties(page)
        setStates(states.length > 0 ? states : data.data.data.states)
        if (!onlyStates) {
          if (isNearScreen) {
            const newProperties = properties.concat(data.data.data.properties)
            setProperties(newProperties)
          } else {
            setProperties(data.data.data.properties)
          }
          setCount(data.data.data.count)
          setSales(data.data.data.operation.venta)
          setRents(data.data.data.operation.alquiler)
          setPages(data.data.data.pages)
        }
      } else {
        let data = await fetchProperties(page)
        setStates(states.length > 0 ? states : data.data.data.states)
        if (!onlyStates) {
          setProperties(data.data.data.properties)
          setCount(data.data.data.count)
          setSales(data.data.data.operation.venta)
          setRents(data.data.data.operation.alquiler)
          setPages(data.data.data.pages)
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const applyFilterFromStorage = async (filter: any) => {
    let { bedrooms, bathrooms, categories, states, operations, tags } = await selectedUnselected(
      true,
      filter
    )
    setBedrooms(bedrooms)
    setBathrooms(bathrooms)
    setCategories(categories)
    setTags(tags)
    setStates(states)
    setOperations(operations)
  }

  const selectedUnselected = async (status: boolean, filter: any) => {
    let _bedrooms = bedrooms
    let _bathrooms = bathrooms
    let _categories = categories
    let _states = states
    let _operations = operations
    let _tags = tags
    switch (filter.type) {
      case Constants.FILTER_OPERATION:
        await Promise.all(
          _operations.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
      case Constants.FILTER_BATHROOM:
        await Promise.all(
          _bathrooms.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
      case Constants.FILTER_BEDROOM:
        await Promise.all(
          _bedrooms.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
      case Constants.FILTER_CATEGORY:
        await Promise.all(
          _categories.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
      case Constants.FILTER_STATE:
        await Promise.all(
          _states.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
      case Constants.FILTER_TAGS:
        await Promise.all(
          _tags.map((item) => {
            if (filter.name.includes(item.name)) {
              item.selected = status
            }
          })
        )
        break
    }
    return {
      bedrooms: _bedrooms,
      bathrooms: _bathrooms,
      categories: _categories,
      states: _states,
      operations: _operations,
      tags: _tags,
    }
  }

  const unselectedAll = async () => {
    let _bedrooms = bedrooms
    let _bathrooms = bathrooms
    let _categories = categories
    let _states = states
    let _operations = operations
    let _tags = tags
    await Promise.all(
      _operations.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    await Promise.all(
      _bathrooms.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    await Promise.all(
      _bedrooms.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    await Promise.all(
      _categories.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    await Promise.all(
      _states.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    await Promise.all(
      _tags.map((item: { selected: boolean }) => {
        item.selected = false
      })
    )
    return {
      bedrooms: _bedrooms,
      bathrooms: _bathrooms,
      categories: _categories,
      states: _states,
      operations: _operations,
      tags: _tags,
    }
  }

  const removeFilter = async (filter: any = null) => {
    let data: any
    let filtersResult: any[] = []
    if (filter !== null) {
      data = await selectedUnselected(false, filter)
      filtersResult = filters.filter((item: any) => item.name !== filter.name)
      setFilters(filtersResult)
      if (!filtersResult.length) {
        sessionStorage.removeItem('filters')
      }
    } else {
      data = await unselectedAll()
      setSearch('')
      setFilters(filtersResult)
      sessionStorage.removeItem('filters')
    }
    setStates(data.states)
    setCategories(data.categories)
    setBathrooms(data.bathrooms)
    setBedrooms(data.bedrooms)
    setTags(data.tags)
    handleButton(false)
  }

  const applyFilter = async (
    item: { selected: boolean; name: any; id: any },
    text: any,
    type: string
  ) => {
    item.selected = true
    let filter: { name: any; type?: any; filter?: any } | null = null
    if (
      type === Constants.FILTER_CATEGORY ||
      type === Constants.FILTER_STATE ||
      type === Constants.FILTER_TAGS
    ) {
      filter = { name: `${item.name} ${text}`, type: type, filter: item.id }
    } else {
      filter = { name: `${item.name} ${text}`, type: type, filter: item.name }
    }
    const filterTmp = filters.find((item) => item.name.trim() === filter?.name.trim())
    if (!filterTmp) {
      setFilters([...filters, filter])
      handleButton(false)
    } else {
      removeFilter(filter)
    }
  }

  const fetchProperties = async (page: number) => {
    try {
      return await axios.post(`${Constants.BASE_URL}/properties/all_pagination?page=${page}`, {
        filters,
        search,
        prices,
        growth: false,
        orderBy,
      })
    } catch (error) {
      throw error
    }
  }

  const onPrev = async () => {
    await setPage(page - 1)
  }

  const onNext = async () => {
    await setPage(page + 1)
  }

  const onPage = async (page: number) => {
    await setPage(page)
  }

  const handleButton = async (state: boolean = !isOpen) => {
    await setIsOpen(state)
  }

  const FiltersDesktop = () => {
    return (
      <React.Fragment>
        <div className="box-content-filters">
          <span className="filter-title secondary-font">FILTROS</span>
          <div className="filters">
            {filters.map((filter) => (
              <div className="filter-item">
                <span className="secondary-font">{filter.name}</span>
                <img src={clear} onClick={() => removeFilter(filter)} alt="" />
              </div>
            ))}
          </div>
          {filters.length > 0 ? (
            <div className="filter-trash">
              <div className="filter-trash-button" onClick={() => removeFilter()}>
                <img src={trash} alt="" />
                <span className="secondary-font">Borrar filtros</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="box-content-filters">
          <span className="filter-title secondary-font">TIPO DE OPERACIÓN</span>
          <div className="d-inline">
            {operations.map((item: any) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_OPERATION)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">
                  {item.name} ({item.name === 'Venta' ? sales : rents})
                </label>
              </div>
            ))}
          </div>
          <hr />
          <span className="filter-title secondary-font">UBICACIÓN</span>
          <div className="d-inline">
            {states.map((item) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_STATE)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">
                  {item.name} ({item.count})
                </label>
              </div>
            ))}
          </div>
          <hr />
          <span className="filter-title secondary-font">TIPO DE PROPIEDADES</span>
          <div className="property-type d-inline">
            {(showMoreProperties ? categories : categories.slice(0, 3)).map((item) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_CATEGORY)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">{item.name}</label>
              </div>
            ))}
            <div style={{ textAlign: 'right' }}>
              <a onClick={() => setShowMoreProperties(!showMoreProperties)}>
                {showMoreProperties ? 'Ver menos' : 'Ver más'}
              </a>
            </div>
          </div>
          <hr />
          <span className="filter-title secondary-font">CARACTERISTICAS</span>
          <div className="property-type d-inline">
            {tags.map((item) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_TAGS)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">{item.name}</label>
              </div>
            ))}
          </div>
          <hr />
          <div className="bedroom">
            <span className="filter-title secondary-font">DORMITORIOS</span>
            <div className="box-items">
              {bedrooms.map((item) => (
                <div
                  className={`box-item ${item.selected && 'active'}`}
                  onClick={() => applyFilter(item, 'Dormitorios', Constants.FILTER_BEDROOM)}
                >
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="bathroom">
            <span className="filter-title secondary-font">BAÑOS</span>
            <div className="box-items">
              {bathrooms.map((item) => (
                <div
                  className={`box-item ${item.selected && 'active'}`}
                  onClick={() => applyFilter(item, 'Baños', Constants.FILTER_BATHROOM)}
                >
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const FiltersMobile = () => {
    return (
      <React.Fragment>
        <div
          className="box-content-filters"
          style={{ marginTop: '1rem', marginBottom: '0rem', paddingBottom: '2rem' }}
        >
          <span className="filter-title secondary-font">TIPO DE OPERACIÓN</span>
          <div className="d-inline">
            {operations.map((item: any) => (
              <div
                key={item}
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_OPERATION)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">
                  {item.name} ({item.name === 'Venta' ? sales : rents})
                </label>
              </div>
            ))}
          </div>
          <hr />
          <span className="filter-title secondary-font">UBICACIÓN</span>
          <div className="d-inline">
            {states.map((item) => (
              <div
                key={item}
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_STATE)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    {' '}
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">
                  {item.name} ({item.count})
                </label>
              </div>
            ))}
          </div>
          <hr />
          <span className="filter-title secondary-font">TIPO DE PROPIEDADES</span>
          <div className="property-type d-inline">
            {(showMoreProperties ? categories : categories.slice(0, 3)).map((item) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_CATEGORY)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    {' '}
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">{item.name}</label>
              </div>
            ))}
            <div style={{ textAlign: 'right' }}>
              <a onClick={() => setShowMoreProperties(!showMoreProperties)}>
                {showMoreProperties ? 'Ver menos' : 'Ver más'}
              </a>
            </div>
          </div>
          <hr />
          <span className="filter-title secondary-font">CARACTERISTICAS</span>
          <div className="property-type d-inline">
            {tags.map((item) => (
              <div
                className="checkbox-button"
                onClick={() => applyFilter(item, '', Constants.FILTER_TAGS)}
              >
                {item.selected ? (
                  <div className="checkbox-marked">
                    {' '}
                    <img src={done} alt="" />
                  </div>
                ) : (
                  <div className="checkbox-unmarked"></div>
                )}
                <label className="type-label secondary-font">{item.name}</label>
              </div>
            ))}
          </div>
          <hr />
          <div className="bedroom">
            <span className="filter-title secondary-font">DORMITORIOS</span>
            <div className="box-items">
              {bedrooms.map((item) => (
                <div
                  className={`box-item ${item.selected && 'active'}`}
                  onClick={() => applyFilter(item, 'Dormitorios', Constants.FILTER_BEDROOM)}
                >
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="bathroom">
            <span className="filter-title secondary-font">BAÑOS</span>
            <div className="box-items">
              {bathrooms.map((item) => (
                <div
                  className={`box-item ${item.selected && 'active'}`}
                  onClick={() => applyFilter(item, 'Baños', Constants.FILTER_BATHROOM)}
                >
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      {loading && <Loading />}
      <Navigation />
      <div className="col-12 text-center d-none d-md-block">
        <img
          src={`${Constants.BASE_URL}${bannerDesktop}`}
          alt=""
          style={{ width: '100%', height: 'auto', marginTop: '60px' }}
        />
      </div>
      <div className="row" style={{ marginBottom: '4rem' }}>
        <div className="col-12 d-block d-md-none" style={{ padding: '0rem' }}>
          <img
            src={`${Constants.BASE_URL}${bannerMobile || bannerDesktop}`}
            alt=""
            style={{ width: '100%', height: 'auto', marginTop: '50px' }}
          />
        </div>
      </div>
      <div
        className="container"
        id="properties"
        style={{ marginTop: bannerDesktop ? '1rem' : '6rem' }}
      >
        <div className="row">
          <div className="col-6 filter-type-operation">
            <span className="count-properties secondary-font desktop">Búsqueda avanzada</span>
            <div className="col-12 filter mobile text-center">
              <div className="filter-mobile" onClick={() => handleButton()}>
                <img src={filterIcon} alt="" width="20" />
                <span className="secondary-font">Filtrar</span>
              </div>
            </div>
          </div>
          <div className="col-6 filter-type-operation order-by">
            <select
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              style={{ maxWidth: '100%' }}
            >
              <option value="last-ingress">Últimos ingresos</option>
              <option value="lower-price">Menor precio</option>
              <option value="high-price">Mayor precio</option>
            </select>
          </div>
          {filters.length > 0 ? (
            <div className="col-12 mobile-filters">
              <div className="box-content-filters">
                <span className="filter-title secondary-font">FILTROS</span>
                <div className="filters">
                  {filters.map((filter) => (
                    <div className="filter-item">
                      <span className="secondary-font">{filter.name}</span>
                      <img src={clear} onClick={() => removeFilter(filter)} alt="" />
                    </div>
                  ))}
                </div>
                {filters.length > 0 ? (
                  <div className="filter-trash">
                    <div className="filter-trash-button" onClick={() => removeFilter()}>
                      <img src={trash} alt="" />
                      <span className="secondary-font">Borrar filtros</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-3 desktop-filter">
            {/*<div className="d-inline" style={{ position: 'relative' }}>
              <input
                value={search}
                style={{
                  padding: '0px 5px',
                  width: '250px',
                  marginRight: '0.5rem',
                }}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Ubicación, palabras claves o referencia"
              />
              {search ? (
                <button
                  onClick={() => setSearch('')}
                  style={{ position: 'absolute', top: 0, right: 15, width: 2 }}
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                ></button>
              ) : null}
            </div>
            <br />
            <br />
            <span className="count-properties secondary-font desktop">
              <b>Precio</b>
            </span>
            <div className="property-type d-inline">
              <br />
              <input
                value={minPrice}
                style={{
                  padding: '0px 5px',
                  width: '80px',
                  marginRight: '0.5rem',
                }}
                onChange={(e) => setMinPrice(e.target.value)}
                placeholder="Min"
              />
              <input
                value={maxPrice}
                style={{
                  padding: '0px 5px',
                  width: '80px',
                  marginRight: '0.5rem',
                }}
                onChange={(e) => setMaxPrice(e.target.value)}
                placeholder="Max"
              />
              <select
                onChange={(e) => setCurrency(e.target.value)}
                style={{ width: '70px', padding: '0px 5px', height: '28px' }}
              >
                {MONEY.map((item) => (
                  <option key={item} selected={item === currency} value={item}>
                    {item.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <hr />
            <button
              style={{
                background: '#013399',
                color: 'white',
                border: 'none',
                padding: '0.5rem 1rem',
              }}
              onClick={() => getProperties()}
            >
              Buscar
            </button>
            <br />
            <br />
            <br />*/}
            <FiltersDesktop />
          </div>
          <div className="col-12 col-md-9">
            {properties.length > 0 ? (
              <VerticalCard
                properties={properties}
                pagination={
                  !isMobile ? (
                    <Pagination
                      pages={pages}
                      page={page}
                      onPageProp={(page: number) => onPage(page)}
                      onPrevProp={onPrev}
                      onNextProp={onNext}
                      marginTop="1rem"
                    />
                  ) : null
                }
              />
            ) : null}
            {isMobile && properties.length > 0 ? <div id="observer" ref={elementRef}></div> : ''}
          </div>
        </div>
      </div>
      {isOpen ? (
        <div className="modal-filters">
          <div className="row">
            <div className="col-12 text-right">
              <img
                src={closeBlue}
                style={{ cursor: 'pointer' }}
                onClick={() => handleButton(false)}
                alt=""
              />
            </div>
          </div>
          <div className="row">
            {/*<div
              className="d-flex"
              style={{ position: 'relative', justifyContent: 'center', flexDirection: 'column' }}
            >
              <input
                value={search}
                style={{
                  padding: '0px 5px',
                  width: '250px',
                  marginRight: '0.5rem',
                }}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Ubicación, palabras claves o referencia"
              />
              <br />
              <span className="count-properties secondary-font mobile">
                <b>Precio</b>
              </span>
              <div className="property-type d-inline">
                <input
                  value={minPrice}
                  style={{
                    padding: '0px 5px',
                    width: '80px',
                    marginRight: '0.5rem',
                  }}
                  onChange={(e) => setMinPrice(e.target.value)}
                  placeholder="min"
                />
                <input
                  value={maxPrice}
                  style={{
                    padding: '0px 5px',
                    width: '80px',
                    marginRight: '0.5rem',
                  }}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  placeholder="Max"
                />
                <select
                  onChange={(e) => setCurrency(e.target.value)}
                  style={{ width: '60px', padding: '0px 5px', height: '28px' }}
                >
                  {MONEY.map((item) => (
                    <option key={item} selected={item === currency} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <hr />
              <button
                style={{
                  background: '#013399',
                  color: 'white',
                  border: 'none',
                  padding: '0.5rem 1rem',
                }}
                onClick={() => {
                  getProperties()
                  setIsOpen(false)
                }}
              >
                Buscar
              </button>
            </div>
            <br />
            <br />*/}
            <div className="col-12">
              <FiltersMobile />
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </div>
  )
}
