/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Constants } from '../../../Constants'
import './Testimonies.scss'
import { Modal } from 'react-bootstrap'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

export default function Testimonies() {
  const [testimonies, setTestimonies] = useState([])
  const [showModal, setShowModal] = useState<any>(false)

  const fetch = async () => {
    try {
      const resp = await axios.get(`${Constants.BASE_URL}testimonies/random`)
      setTestimonies(resp.data.testimonies)
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const getDescription = (description: string) => {
    if (description.length > 130) {
      return description.slice(0, 130) + '...'
    }
    return description
  }

  return (
    /* COMPONENT LIKE GOOGLE RATINGS CARDS WITH BOOTSTRAP */
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 text-center">
          <h3>¿POR QUÉ ELEGIRNOS?</h3>
        </div>
      </div>
      <div className="row row-rating" style={{ marginTop: '2rem' }}>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={5000}
          showDots={false}
          arrows={false}
          autoPlay={true}
        >
          {testimonies.map((testimony: any, index: number) => (
            <div key={index} className="card">
              <div className="card-header">
                {testimony.image ? (
                  <img src={testimony.image} alt={testimony.name} className="card-avatar" />
                ) : (
                  <img
                    src="https://www.gravatar.com/avatar/?d=mp"
                    alt={testimony.name}
                    className="card-avatar"
                  />
                )}
                <span>
                  <h5 style={{ fontSize: 16, marginBottom: 0 }}>{testimony.name}</h5>
                  <p style={{ color: 'gray', fontSize: 14 }}>
                    {new Date(testimony.created_at).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </p>
                </span>
              </div>
              <div className="card-body">
                <div className="stars">
                  {Array.from({ length: 5 }, (_, i) =>
                    i < testimony.rating ? (
                      <i style={{ color: '#F1C40F' }} className="bi bi-star-fill" key={i}></i>
                    ) : (
                      <i className="bi bi-star" key={i}></i>
                    )
                  )}
                </div>
                <p className="card-text">
                  {getDescription(testimony.description)}
                  {testimony.description.length > 130 ? (
                    <a
                      className="show-more"
                      onClick={() => setShowModal(testimony)}
                      style={{ marginLeft: '0.25rem' }}
                    >
                      Leer más
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              {showModal.image ? (
                <img src={showModal.image} alt={showModal.name} className="card-avatar" />
              ) : (
                <img
                  src="https://www.gravatar.com/avatar/?d=mp"
                  alt={showModal.name}
                  className="card-avatar"
                />
              )}
              <span>
                <h5 style={{ fontSize: 16, marginBottom: 0 }}>{showModal.name}</h5>
                <p style={{ color: 'gray', fontSize: 14 }}>
                  {new Date(showModal.created_at).toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </span>
            </div>
            <div className="card-body">
              <div className="stars">
                {Array.from({ length: 5 }, (_, i) =>
                  i < showModal.rating ? (
                    <i style={{ color: '#F1C40F' }} className="bi bi-star-fill" key={i}></i>
                  ) : (
                    <i className="bi bi-star" key={i}></i>
                  )
                )}
              </div>
              <p className="card-text">{showModal.description}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
