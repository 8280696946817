/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './Footer.scss'
//icons
import arrowUp from '../../assets/icons/arrow-upward-24-px.svg'
import ic_location from '../../assets/icons/mm_ic_location.svg'
import ic_phone from '../../assets/icons/mm_ic_phone.svg'
import ic_email from '../../assets/icons/mm_ic_email.svg'
//redes
import ic_facebook from '../../assets/icons/mm_ic_facebook.svg'
import ic_youtube from '../../assets/icons/mm_ic_youtube.svg'
import ic_wpp from '../../assets/icons/mm_ic_wpp.svg'
import ic_twitter from '../../assets/icons/mm_ic_twitter.svg'
import ic_telegram from '../../assets/icons/mm_ic_telegram.svg'
import ic_linkedin from '../../assets/icons/mm_ic_linkedin.svg'
import ic_instagram from '../../assets/icons/mm_ic_instagram.svg'
import ic_ciu from '../../assets/images/CIU.png'
import ic_cim from '../../assets/images/CIM.png'
import ic_crs from '../../assets/images/CRS.png'
import ministerio from '../../assets/images/ministerio.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import { SmoothScrolling } from '../smoothScrolling/SmoothScrolling'
import { fetchAccount, postSub } from '../../services/SharedServices'
import { toast, ToastContainer } from 'react-toastify'
import Loading from '../loading/Loading'
import { Constants } from '../../Constants'
import imageFooterDefault from '../../assets/images/principal-banner.jpeg'
import { fetchHome, fetchMembers } from '../../services/HomeServices'

export default function Footer({ external, history, hideSubscribe }: any) {
  const [facebook, setFacebook] = useState('')
  const [instagram, setInstagram] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [telegram, setTelegram] = useState('')
  const [twitter, setTwitter] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [youtube, setYoutube] = useState('')
  const [wpp, setWpp] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(false)
  const [footerImage, setFooterImage] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    getAccount()
    fetch()
    getMembers()
  }, [])

  const scrollTo = async (section: any) => {
    if (external && section !== 'scroll-top') {
      history.push('/')
    }
    setTimeout(
      () => {
        SmoothScrolling.scrollTo(section)
      },
      external ? 500 : 100
    )
    document.body.style.overflow = 'unset'
  }

  const getAccount = async () => {
    try {
      let response = await fetchAccount()
      const { facebook, instagram, linkedin, telegram, twitter, whatsapp, youtube } =
        response.data.data.config
      setFacebook(facebook)
      setInstagram(instagram)
      setLinkedin(linkedin)
      setTelegram(telegram)
      setTwitter(twitter)
      setWhatsapp(whatsapp)
      setYoutube(youtube)
    } catch (error) {}
  }

  const getMembers = async () => {
    try {
      let response = await fetchMembers()
      setMembers(response.data.data)
    } catch (error) {}
  }

  const _postSub = async () => {
    setLoading(true)
    try {
      if (!wpp || !name) {
        setLoading(false)
        toast.error(`Debe completar tu nombre y número de whatsapp`, {
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnHover: false,
        })
        return
      }
      await postSub({
        name,
        wpp,
        email,
      })
      setName('')
      setWpp('')
      setEmail('')
      setLoading(false)
      toast(`Registrado con éxito`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    } catch (error: any) {
      setLoading(false)
      toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
      })
    }
  }

  const fetch = async () => {
    setLoading(true)
    try {
      let response = await fetchHome()
      const { footerImage } = response.data.data.config
      setFooterImage(footerImage)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {loading && <Loading />}
      {!hideSubscribe ? (
        <div
          className="container-fluid footer-subscribe"
          style={{
            backgroundImage: footerImage
              ? `url(${Constants.BASE_URL}/${footerImage})`
              : `url(${imageFooterDefault})`,
          }}
        >
          <div className="footer-subscribe-container">
            <h3>Recibí propiedades en tu whatsapp o email</h3>
            <div>
              <div>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="* Nombre"
                />
                <input
                  value={wpp}
                  onChange={(e) => setWpp(e.target.value)}
                  placeholder="* Whatsapp"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <button onClick={() => _postSub()}>Suscribirme</button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="container-fluid footer desktop-footer">
        <div className="container">
          <div className="row" style={{ position: 'relative' }}>
            <div className="footer-item col-12 col-md-3 social">
              <div className="details-item" style={{ marginTop: '0rem' }}>
                <img
                  src={ministerio}
                  alt=""
                  style={{ width: '170px' }}
                  onClick={() =>
                    window.open(
                      'http://apps.mintur.gub.uy/operadores/operador.php?tipo=4&numero=1932',
                      '_blank'
                    )
                  }
                />
              </div>
              {/*<label className="title">SOCIAL</label>
              {facebook !== '' ? (
                <div className="details-item">
                  <img src={ic_facebook} alt="" width="22" />
                  <a href={facebook} className="secondary-font">
                    Facebook
                  </a>
                </div>
              ) : null}
              {instagram !== '' ? (
                <div className="details-item">
                  <img src={ic_instagram} alt="" width="22" />
                  <a className="secondary-font" href={instagram}>
                    Instagram
                  </a>
                </div>
              ) : null}

              {linkedin !== '' ? (
                <div className="details-item">
                  <img src={ic_linkedin} alt="" width="22" />
                  <a className="secondary-font" href={linkedin}>
                    Linkedin
                  </a>
                </div>
              ) : null}
              {twitter !== '' ? (
                <div className="details-item">
                  <img src={ic_twitter} alt="" width="22" />
                  <a className="secondary-font" href={twitter}>
                    Twitter
                  </a>
                </div>
              ) : null}
              {whatsapp !== '' ? (
                <div className="details-item">
                  <img src={ic_wpp} alt="" width="22" />
                  <a className="secondary-font" href={whatsapp}>
                    Whatsapp
                  </a>
                </div>
              ) : null}
              {telegram !== '' ? (
                <div className="details-item">
                  <img src={ic_telegram} alt="" width="22" />
                  <a className="secondary-font" href={telegram}>
                    Telegram
                  </a>
                </div>
              ) : null}
              {youtube !== '' ? (
                <div className="details-item">
                  <img src={ic_youtube} alt="" width="22" />
                  <a className="secondary-font" href={youtube}>
                    Youtube
                  </a>
                </div>
              ) : null}*/}
            </div>

            {/*<div className="footer-item col-12 col-md-3">
              <label className="title">TIPOS DE PROPIEDADES</label>
              <div style={{ display: 'flex' }}>
                <div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('1', 'Apartamentos')}>
                      Apartamentos
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('2', 'Campos')}>
                      Campos
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('3', 'Casas')}>
                      Casas
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('4', 'Terrenos')}>
                      Terrenos
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a
                      className="secondary-font"
                      onClick={() => searchAction('6', 'Locales Comerciales')}
                    >
                      Locales Comerciales
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('7', 'Oficinas')}>
                      Oficinas
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('8', 'Chacras')}>
                      Chacras
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('9', 'Galpónes')}>
                      Galpones
                    </a>
                  </div>
                  <div className="details-item">
                    <span style={{ color: 'white' }}>•</span>
                    <a className="secondary-font" onClick={() => searchAction('5', 'Otros')}>
                      Otros
                    </a>
                  </div>
                </div>
              </div>
              </div>*/}

            <div className="footer-item col-12 col-md-4">
              <label className="title">INTEGRANTES DE</label>
              {members.map((member: any, index: number) => (
                <div className="details-item" key={index}>
                  <img src={`${Constants.BASE_URL}${member.image}`} alt="" width="30" height="30" />
                  <a className="secondary-font" target="_blank" rel="noreferrer" href={member.url}>
                    {member.title}
                  </a>
                </div>
              ))}
            </div>

            <div className="footer-item col-12 col-md-5">
              <label className="title">DATOS DE CONTACTO</label>
              <div className="details-item">
                <img src={ic_location} alt="" width="22" />
                <a
                  className="secondary-font"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com.uy/maps/place/MGI+Mario+M%C3%A9ndez+Gesti%C3%B3n+Inmobiliaria/@-34.3399459,-56.7174786,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1776aecb47e01:0x69075780c50c890c!8m2!3d-34.3399503!4d-56.7152899"
                >
                  Batlle y Ordoñez 414, San José de Mayo
                </a>
              </div>
              <div className="details-item">
                <img src={ic_phone} alt="" width="22" />
                <a className="secondary-font" href={`tel:59891999222`}>
                  091 999 222
                </a>
              </div>
              <div className="details-item">
                <img src={ic_email} alt="" width="22" />
                <a className="secondary-font" href={`mailto:mgi@mariomendez.uy`}>
                  mgi@mariomendez.uy
                </a>
              </div>
            </div>
            <div className="arrow-up">
              <div className="border-image" onClick={() => scrollTo('scroll-top')}>
                <img src={arrowUp} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid footer mobile">
        <div className="container background-color-footer">
          <div className="row">
            <div className="col-12 footer-item">
              {/*<label className="title">SOCIAL</label>
              {facebook !== '' ? (
                <div className="details-item">
                  <img src={ic_facebook} alt="" width="22" />
                  <a className="secondary-font" href={facebook}>
                    Facebook
                  </a>
                </div>
              ) : null}
              {instagram !== '' ? (
                <div className="details-item">
                  <img src={ic_instagram} alt="" width="22" />
                  <a className="secondary-font" href={instagram}>
                    Instagram
                  </a>
                </div>
              ) : null}
              {linkedin !== '' ? (
                <div className="details-item">
                  <img src={ic_linkedin} alt="" width="22" />
                  <a className="secondary-font" href={linkedin}>
                    Linkedin
                  </a>
                </div>
              ) : null}
              {twitter !== '' ? (
                <div className="details-item">
                  <img src={ic_twitter} alt="" width="22" />
                  <a className="secondary-font" href={twitter}>
                    Twitter
                  </a>
                </div>
              ) : null}
              {whatsapp !== '' ? (
                <div className="details-item">
                  <img src={ic_wpp} alt="" width="22" />
                  <a className="secondary-font" href={whatsapp}>
                    Whatsapp
                  </a>
                </div>
              ) : null}
              {telegram !== '' ? (
                <div className="details-item">
                  <img src={ic_telegram} alt="" width="22" />
                  <a className="secondary-font" href={telegram}>
                    Telegram
                  </a>
                </div>
              ) : null}
              {youtube !== '' ? (
                <div className="details-item">
                  <img src={ic_youtube} alt="" width="22" />
                  <a className="secondary-font" href={youtube}>
                    Youtube
                  </a>
                </div>
              ) : null}*/}

              {/*<label className="title" style={{ marginTop: '2rem' }}>
                TIPOS DE PROPIEDADES
              </label>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('1', 'Apartamentos')}>
                  Apartamentos
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('2', 'Campos')}>
                  Campos
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('3', 'Casas')}>
                  Casas
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('4', 'Terrenos')}>
                  Terrenos
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a
                  className="secondary-font"
                  onClick={() => searchAction('6', 'Locales Comerciales')}
                >
                  Locales Comerciales
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('7', 'Oficinas')}>
                  Oficinas
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('8', 'Chacras')}>
                  Chacras
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('9', 'Galpónes')}>
                  Galpones
                </a>
              </div>
              <div className="details-item">
                <span style={{ color: 'white' }}>•</span>
                <a className="secondary-font" onClick={() => searchAction('5', 'Otros')}>
                  Otros
                </a>
              </div>*/}

              <label className="title" style={{ marginTop: '2rem' }}>
                INTEGRANTES DE
              </label>
              {members.map((member: any, index: number) => (
                <div className="details-item" key={index}>
                  <img src={`${Constants.BASE_URL}${member.image}`} alt="" width="30" height="30" />
                  <a className="secondary-font" target="_blank" rel="noreferrer" href={member.url}>
                    {member.title}
                  </a>
                </div>
              ))}

              <label className="title" style={{ marginTop: '2rem' }}>
                DATOS DE CONTACTO
              </label>
              <div className="details-item">
                <img src={ic_location} alt="" width="25" />
                <a
                  className="secondary-font"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com.uy/maps/place/MGI+Mario+M%C3%A9ndez+Gesti%C3%B3n+Inmobiliaria/@-34.3399459,-56.7174786,17z/data=!3m1!4b1!4m5!3m4!1s0x95a1776aecb47e01:0x69075780c50c890c!8m2!3d-34.3399503!4d-56.7152899"
                >
                  Batlle y Ordoñez 414, <br />
                  San José de Mayo
                </a>
              </div>
              <div className="details-item">
                <img src={ic_phone} alt="" width="25" />
                <a className="secondary-font" href={`tel:59891999222`}>
                  091 999 222
                </a>
              </div>
              <div className="details-item">
                <img src={ic_email} alt="" width="25" />
                <a className="secondary-font" href={`mailto:mgi@mariomendez.uy`}>
                  mgi@mariomendez.uy
                </a>
              </div>
              <div className="details-item" style={{ marginTop: '2rem' }}>
                <img
                  src={ministerio}
                  alt=""
                  style={{ width: '100%' }}
                  onClick={() =>
                    window.open(
                      'http://apps.mintur.gub.uy/operadores/operador.php?tipo=4&numero=1932',
                      '_blank'
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-credits">
        <div className="row">
          <div className="col-12 col-md-6 reservation order-2 order-md-1">
            <label>© {new Date().getFullYear()} All Rights Reserved by</label>
            <a href="https://sayasoftware.com/" target="_blank" rel="noreferrer">
              Saya Software
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
